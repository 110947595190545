import avantage from './../../assets/images/Articles/Avantage.webp';
import populaire from './../../assets/images/Articles/Populaires.webp';
import entretenir from './../../assets/images/Articles/Entretenir.webp';
import ecologique from './../../assets/images/Articles/Ecologique.webp';
import choisir from './../../assets/images/Articles/Choisir.webp';
import renovation from './../../assets/images/Articles/Renovation.webp';

export const articleAdvantages = {
  title: "Les Avantages des Vérandas pour les Maisons",
  introduction: "Lorsqu'il s'agit d'améliorer et d'agrandir son espace de vie, l'ajout d'une véranda représente une solution polyvalente et élégante. PatekVerandas, spécialiste de la véranda à Lille, vous explique les nombreux avantages qu'une véranda peut apporter à votre maison. Que vous soyez intéressé par une véranda aluminium moderne ou par une véranda sur-mesure, découvrez pourquoi cette extension est l'une des meilleures options pour transformer votre habitat.",
  sections: [
    {
      heading: "Agrandissement Harmonieux de l'Espace",
      content: "L'un des principaux avantages d'une véranda est l'agrandissement de l'espace habitable. Contrairement à une extension en briques ou en parpaings, une véranda offre une transition fluide entre l'intérieur et l'extérieur. Cela crée une pièce supplémentaire qui peut être utilisée comme salon, salle à manger, bureau ou jardin d'hiver. À Lille, l'installation d'une véranda peut se faire rapidement et simplement, souvent sans nécessiter de permis de construire si la surface est inférieure à 20 m².",
    },
    {
      heading: "Apport de Lumière Naturelle",
      content: "Les vérandas sont réputées pour leur capacité à inonder de lumière naturelle les espaces intérieurs grâce à leurs grandes surfaces vitrées. Cette luminosité améliore le confort de vie en rendant les pièces adjacentes plus lumineuses et agréables. Une véranda moderne peut également être équipée de toitures vitrées pour maximiser cet apport lumineux et permettre de profiter de la vue du ciel étoilé la nuit.",
    },
    {
      heading: "Isolation et Confort Thermique",
      content: "Grâce aux avancées technologiques, les vérandas modernes offrent une excellente isolation thermique et acoustique. Chez PatekVerandas, nous utilisons des matériaux de haute performance comme l'aluminium à rupture de pont thermique et le double vitrage pour garantir un confort optimal toute l'année. Ces innovations permettent de maintenir une température stable, réduisant ainsi les coûts de chauffage en hiver et conservant une fraîcheur agréable en été.",
    },
    {
      heading: "Valorisation Immobilière",
      content: "Une véranda bien conçue augmente considérablement la valeur de votre propriété. En ajoutant une pièce supplémentaire et en améliorant l'esthétique de votre maison, vous pouvez attirer davantage d'acheteurs potentiels et justifier un prix de vente plus élevé. PatekVerandas, en tant que constructeur de vérandas à Lille, propose des solutions sur-mesure pour s'adapter parfaitement à votre style de maison et à vos besoins spécifiques.",
    },
    {
      heading: "Polyvalence et Personnalisation",
      content: "Les vérandas offrent une grande polyvalence. Elles peuvent être utilisées pour divers usages, du salon à la salle à manger, en passant par le bureau ou même une piscine intérieure. PatekVerandas vous offre la possibilité de personnaliser votre véranda avec des matériaux comme le bois, le PVC ou l'aluminium, chacun ayant ses propres avantages en termes d'esthétique et de performance énergétique.",
    },
    {
      heading: "Protection contre les Intempéries",
      content: "Une véranda bien conçue vous permet de profiter de votre jardin toute l'année, même par mauvais temps. Elle offre une protection contre la pluie, le vent et la neige, tout en vous permettant de vous sentir proche de la nature sans les inconvénients des éléments. À Lille, où le climat peut être capricieux, une véranda sur-mesure est une solution idéale pour prolonger l'utilisation de votre espace extérieur.",
    }
  ],
  conclusion: "En conclusion, l'installation d'une véranda offre une multitude d'avantages, allant de l'agrandissement de l'espace de vie à l'amélioration de la valeur immobilière, en passant par l'apport de lumière naturelle et le confort thermique. PatekVerandas, entreprise spécialisée dans les vérandas à Lille, est votre partenaire de confiance pour la réalisation de projets sur-mesure qui transformeront votre maison en un espace encore plus accueillant et fonctionnel. Pour en savoir plus sur nos offres et obtenir un devis personnalisé, n'hésitez pas à nous contacter.",
  invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
  buttonLabel: "Demander un devis",
  image: avantage // Remplacez ceci par l'URL de votre image
};  
export const articleTypes2024 = {
title: "Types de Vérandas Populaires en 2024",
introduction: "L'année 2024 apporte son lot de tendances et d'innovations dans le domaine des vérandas. Pour ceux qui envisagent d'agrandir leur maison avec une véranda à Lille, il est essentiel de connaître les différents types de vérandas disponibles. Que vous recherchiez une véranda moderne, en aluminium ou sur-mesure, voici un guide des styles de vérandas les plus populaires cette année.",
sections: [
  {
    heading: "La Véranda Moderne",
    content: "La véranda moderne se distingue par son design épuré et ses lignes contemporaines. Elle est parfaite pour ceux qui souhaitent ajouter une touche de modernité à leur maison. Composée principalement de verre et d'aluminium, elle offre une grande luminosité et une isolation thermique optimale grâce à l'utilisation de matériaux avancés. Les vérandas modernes sont souvent équipées de toitures plates ou de dômes vitrés pour maximiser l'entrée de lumière.",
  },
  {
    heading: "La Véranda Verrière",
    content: "Inspirée des ateliers d'artistes, la véranda verrière est idéale pour les amateurs de style industriel. Sa structure fine et entièrement vitrée permet une immersion totale dans la nature environnante tout en restant à l'abri des intempéries. Cette véranda est particulièrement appréciée pour sa capacité à apporter une luminosité exceptionnelle et un charme unique à n'importe quelle maison.",
  },
  {
    heading: "La Véranda Jardin d'Hiver",
    content: "La véranda jardin d'hiver est conçue pour être un espace de transition entre l'intérieur et l'extérieur, permettant de profiter du jardin même pendant les mois les plus froids. Avec une bonne isolation thermique et des options de chauffage adaptées, cette véranda devient un espace confortable tout au long de l'année. Elle peut être utilisée comme un salon, une salle à manger ou même un espace de détente.",
  },
  {
    heading: "La Véranda en Aluminium",
    content: "Les vérandas en aluminium sont très populaires en raison de leur durabilité et de leur faible entretien. L'aluminium, en tant que matériau, offre une grande résistance aux intempéries et peut être thermolaqué dans une variété de couleurs pour s'harmoniser avec le style de votre maison. Ce type de véranda est également très flexible en termes de design, permettant des constructions sur-mesure adaptées à vos besoins spécifiques.",
  },
  {
    heading: "La Véranda Extension",
    content: "La véranda extension est une véritable pièce supplémentaire qui s'intègre parfaitement à la maison existante. Que vous ayez besoin d'un bureau, d'une cuisine supplémentaire ou d'un salon, cette véranda peut être conçue pour répondre à vos besoins spécifiques. Elle est souvent réalisée avec des matériaux de haute performance pour assurer une isolation thermique et acoustique optimale.",
  }
],
conclusion: "En 2024, les vérandas se déclinent en une variété de styles et de matériaux pour répondre à tous les goûts et besoins. Que vous optiez pour une véranda moderne, verrière, jardin d'hiver, en aluminium ou une véranda extension, chaque type offre des avantages uniques en termes de design, de confort et de fonctionnalité. Pour ceux qui cherchent une installation de véranda à Lille, les options sont nombreuses et permettent de créer des espaces de vie lumineux et agréables toute l'année.",
invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
buttonLabel: "Demander un devis",
image: populaire // Remplacez ceci par l'URL de votre image
};
export const articleMaintenance = {
  title: "Comment Entretenir sa Véranda",
  introduction: "L'entretien régulier de votre véranda est essentiel pour préserver sa beauté et sa fonctionnalité. Que votre véranda soit en aluminium, bois, ou PVC, chaque matériau nécessite des soins spécifiques pour assurer sa longévité. PatekVerandas, expert en vérandas à Lille, vous propose des conseils pratiques pour maintenir votre espace de vie extérieur en parfait état.",
  sections: [
    {
      heading: "Nettoyage des Vitrages",
      content: "Les vitrages de votre véranda doivent être nettoyés au moins tous les six mois pour éliminer les saletés, les poussières et les traces. Utilisez de l'eau tiède mélangée à du liquide vaisselle ou du vinaigre blanc, appliquée avec une éponge non abrasive. Évitez les produits chimiques agressifs et les nettoyeurs haute pression qui peuvent endommager les surfaces vitrées et les joints d'étanchéité.",
    },
    {
      heading: "Entretien des Structures en Aluminium",
      content: "L'aluminium, très prisé pour sa durabilité et son faible entretien, nécessite un nettoyage régulier avec de l'eau savonneuse et un chiffon doux. Évitez les produits abrasifs qui pourraient rayer la surface. Assurez-vous également que les rails et les orifices de drainage soient dégagés pour un bon fonctionnement des portes et fenêtres.",
    },
    {
      heading: "Soin des Vérandas en Bois",
      content: "Les vérandas en bois nécessitent une attention particulière. Appliquez annuellement une couche d'huile protectrice pour nourrir le bois et éviter les fissures. Utilisez des produits de nettoyage doux pour éviter d'endommager le matériau. Un traitement antifongique peut également être nécessaire pour protéger contre les champignons.",
    },
    {
      heading: "Nettoyage des Toitures",
      content: "La toiture de votre véranda doit être nettoyée avec précaution pour éviter les dommages. Utilisez un tuyau d'arrosage ou un balai télescopique avec de l'eau savonneuse. Évitez de marcher sur la toiture et n'utilisez pas de nettoyeur haute pression. Il est important de nettoyer les chéneaux et les descentes d'eau au moins deux fois par an, surtout après la chute des feuilles en automne.",
    },
    {
      heading: "Entretien des Sols",
      content: "Le sol de la véranda, qu'il soit en carrelage, parquet ou pierre, doit être nettoyé régulièrement avec un mélange d'eau tiède, de bicarbonate de soude, de vinaigre blanc et de citron. Utilisez une serpillère bien essorée pour éviter l'excès d'eau qui pourrait endommager certains matériaux.",
    },
    {
      heading: "Maintenance Générale",
      content: "Assurez-vous de vérifier et de lubrifier les mécanismes d'ouverture des portes et fenêtres pour garantir un fonctionnement optimal. Inspectez régulièrement les joints et les quincailleries pour prévenir la rouille et les détériorations.",
    }
  ],
  conclusion: "En suivant ces conseils, vous pourrez préserver la beauté et la fonctionnalité de votre véranda pendant de nombreuses années. Pour des conseils personnalisés et des services d'entretien, n'hésitez pas à contacter PatekVerandas, votre spécialiste en vérandas à Lille, pour obtenir un devis et des solutions adaptées à vos besoins.",
  invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
  buttonLabel: "Demander un devis",
  image: entretenir // Remplacez ceci par l'URL de votre image
};
export const articleEcoFriendly = {
title: "Vérandas Écologiques et Durables",
introduction: "Dans un monde de plus en plus conscient de l'importance de la durabilité et de l'impact environnemental, les vérandas écologiques et durables gagnent en popularité. Que vous soyez à Lille ou ailleurs, PatekVerandas vous guide à travers les choix et les innovations pour créer une véranda respectueuse de l'environnement. Découvrez comment une véranda écologique peut non seulement améliorer votre qualité de vie, mais aussi réduire votre empreinte carbone.",
sections: [
  {
    heading: "Matériaux Écologiques",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Aluminium Recyclable</h3>
        <p className="mt-2">L'aluminium est l'un des matériaux les plus utilisés pour les vérandas écologiques. Il est non toxique, recyclable à 100 %, et offre une excellente isolation thermique et phonique. De plus, il nécessite peu d'entretien et est extrêmement durable.</p>

        <h3 className="text-xl font-semibold mt-4">Bois Durables</h3>
        <p className="mt-2">Les essences de bois comme le cèdre rouge et le pin Douglas sont idéales pour les vérandas écologiques. Elles sont naturellement résistantes et offrent une isolation thermique exceptionnelle. Toutefois, le bois nécessite un entretien régulier pour préserver sa longévité.</p>

        <h3 className="text-xl font-semibold mt-4">Vitrages Performants</h3>
        <p className="mt-2">Les vitrages à haute performance sont essentiels pour une véranda écologique. Le double vitrage, voire le triple vitrage dans les régions froides, réduit les déperditions de chaleur et maximise l'efficacité énergétique. Des options comme le vitrage chauffant ou autonettoyant peuvent également être intégrées pour améliorer le confort et réduire l'entretien.</p>
      </>
    )
  },
  {
    heading: "Technologies Innovantes",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Panneaux Solaires Intégrés</h3>
        <p className="mt-2">L'intégration de panneaux solaires dans le toit de la véranda permet de produire de l'électricité pour le chauffage et d'autres besoins énergétiques. Cela réduit la dépendance aux sources d'énergie traditionnelles et diminue les coûts énergétiques à long terme.</p>

        <h3 className="text-xl font-semibold mt-4">Gestion de l'Énergie et Domotique</h3>
        <p className="mt-2">Les systèmes de gestion de l'énergie, combinés à la domotique, permettent un contrôle précis de la consommation énergétique. Vous pouvez gérer à distance l'éclairage, le chauffage et les volets roulants, ce qui améliore le confort tout en minimisant l'impact environnemental.</p>

        <h3 className="text-xl font-semibold mt-4">Ventilation Naturelle</h3>
        <p className="mt-2">Une conception optimisée pour la ventilation naturelle réduit la nécessité d'utiliser l'air conditionné, assurant ainsi une meilleure régulation thermique et un air intérieur plus sain.</p>
      </>
    )
  },
  {
    heading: "Entretien et Durabilité",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Nettoyage Écologique</h3>
        <p className="mt-2">Pour maintenir les performances écologiques de votre véranda, un entretien régulier est crucial. Utilisez des nettoyants doux et écologiques pour les vitrages et les surfaces. Inspectez régulièrement la structure pour prévenir les dégradations et assurer une longue durée de vie.</p>

        <h3 className="text-xl font-semibold mt-4">Toitures Végétalisées</h3>
        <p className="mt-2">Envisagez une toiture végétalisée pour améliorer l'isolation et gérer efficacement les eaux pluviales. Ce type de toiture favorise également la biodiversité et ajoute une dimension esthétique naturelle à votre véranda.</p>
      </>
    )
  }
],
conclusion: "Opter pour une véranda écologique et durable est un choix judicieux pour améliorer votre confort de vie tout en respectant l'environnement. Avec les innovations technologiques et les matériaux durables disponibles aujourd'hui, il est possible de construire une véranda qui est à la fois fonctionnelle et éco-responsable. Pour en savoir plus et obtenir un devis personnalisé pour une véranda à Lille, contactez PatekVerandas, votre expert en vérandas durables.",
invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
buttonLabel: "Demander un devis",
image: ecologique // Remplacez ceci par l'URL de votre image
};
export const articleChoose = {
title: "Comment Choisir sa Véranda",
introduction: "Choisir la véranda idéale pour votre maison peut transformer non seulement l'esthétique de votre domicile mais aussi votre qualité de vie. PatekVerandas, expert en vérandas à Lille, vous guide à travers les critères essentiels pour faire le bon choix. Que vous soyez intéressé par une véranda en aluminium moderne, une véranda sur-mesure ou une solution plus économique, voici ce qu'il faut considérer.",
sections: [
  {
    heading: "Définir Vos Besoins et Votre Budget",
    content: "Le premier pas pour choisir une véranda est de définir vos besoins : quelle sera son utilisation principale ? Sera-t-elle un salon, une salle à manger, un bureau ou une extension de cuisine ? Ensuite, évaluez votre budget. Les prix peuvent varier considérablement en fonction des matériaux, de la taille et des équipements choisis. Pour une véranda en aluminium, comptez entre 1 000 et 1 800 euros par m², tandis qu'une véranda en bois peut coûter jusqu'à 2 000 euros par m².",
  },
  {
    heading: "Choisir les Matériaux",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Aluminium</h3>
        <p className="mt-2">L'aluminium est populaire pour sa durabilité, sa faible maintenance et ses excellentes propriétés isolantes. Il est parfait pour les vérandas modernes et peut être peint dans de nombreuses couleurs pour s'adapter à l'esthétique de votre maison.</p>

        <h3 className="text-xl font-semibold mt-4">Bois</h3>
        <p className="mt-2">Le bois offre une esthétique chaleureuse et naturelle, mais nécessite plus d'entretien pour protéger contre les intempéries et les insectes. Il offre également une excellente isolation thermique et acoustique.</p>

        <h3 className="text-xl font-semibold mt-4">PVC</h3>
        <p className="mt-2">Le PVC est une option économique avec de bonnes performances isolantes et nécessite peu d'entretien. Cependant, il est moins durable que l'aluminium ou le bois et peut se décolorer avec le temps.</p>
      </>
    )
  },
  {
    heading: "Choisir le Type de Toiture",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Toiture en polycarbonate</h3>
        <p className="mt-2">Légère et économique, mais moins isolante.</p>

        <h3 className="text-xl font-semibold mt-4">Toiture en verre</h3>
        <p className="mt-2">Apporte beaucoup de lumière, mais nécessite des vitrages à contrôle solaire pour éviter l'effet de serre.</p>

        <h3 className="text-xl font-semibold mt-4">Toiture mixte</h3>
        <p className="mt-2">Combine panneaux isolants et puits de lumière pour un bon compromis entre isolation et luminosité.</p>
      </>
    )
  },
  {
    heading: "Penser à l'Isolation et à la Ventilation",
    content: "Une bonne isolation est essentielle pour utiliser votre véranda toute l'année. Choisissez des vitrages à isolation renforcée et des matériaux avec de bonnes performances thermiques. La ventilation naturelle est aussi importante pour éviter l'humidité et réguler la température.",
  },
  {
    heading: "Considérer l'Orientation et l'Implantation",
    content: "L'orientation de la véranda affecte son confort thermique et sa luminosité. Par exemple, une orientation sud maximisera l'ensoleillement en hiver, tandis qu'une orientation nord limitera la surchauffe en été. Tenez compte de l'environnement, de l'architecture de votre maison et des réglementations locales lors de la planification.",
  }
],
conclusion: "Choisir la véranda parfaite nécessite une réflexion sur vos besoins, votre budget et les caractéristiques de votre maison. PatekVerandas, entreprise spécialisée à Lille, offre des solutions sur-mesure pour vous aider à réaliser votre projet. Que vous cherchiez une véranda moderne en aluminium ou une solution plus traditionnelle en bois, nous sommes là pour vous accompagner à chaque étape.",
invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
buttonLabel: "Demander un devis",
image: choisir // Remplacez ceci par l'URL de votre image
};
export const articleRenovation = {
title: "Projets de Rénovation incluant une Véranda",
introduction: "La rénovation d'une véranda peut transformer votre espace de vie, améliorer son confort et augmenter la valeur de votre maison. Que vous souhaitiez moderniser une véranda existante ou intégrer des technologies de pointe, PatekVerandas, expert en rénovation de vérandas à Lille, vous guide à travers les étapes clés pour réussir votre projet de rénovation.",
sections: [
  {
    heading: "Évaluer l'État Actuel de la Véranda",
    content: "La première étape de tout projet de rénovation est une évaluation complète de l'état actuel de la véranda. Il est crucial d'inspecter les structures porteuses, les vitrages et les systèmes d'isolation. Des signes tels que de la condensation sur les vitrages, des difficultés à ouvrir les portes coulissantes ou des traces de moisissure sur les structures indiquent qu'une rénovation est nécessaire.",
  },
  {
    heading: "Choisir les Matériaux de Rénovation",
    content: (
      <>
        <h3 className="text-xl font-semibold mt-4">Aluminium</h3>
        <p className="mt-2">L'aluminium est un choix populaire pour la rénovation en raison de sa durabilité et de son entretien minimal. Il permet également des structures fines qui maximisent la surface vitrée, offrant ainsi plus de lumière naturelle.</p>

        <h3 className="text-xl font-semibold mt-4">Bois</h3>
        <p className="mt-2">Le bois, bien que nécessitant un entretien régulier, apporte une chaleur et une esthétique naturelle inégalée. C'est un excellent isolant thermique et acoustique, idéal pour les vérandas utilisées comme espaces de vie toute l'année.</p>

        <h3 className="text-xl font-semibold mt-4">PVC</h3>
        <p className="mt-2">Le PVC est une option économique avec de bonnes performances isolantes. Il est particulièrement adapté aux rénovations où le budget est un facteur clé. Cependant, il offre moins de possibilités esthétiques et peut nécessiter des structures plus épaisses.</p>
      </>
    )
  },
  {
    heading: "Améliorer l'Isolation et le Vitrage",
    content: "Pour une véranda confortable tout au long de l'année, l'isolation est essentielle. Le double vitrage est recommandé pour ses performances isolantes et sa sécurité contre les effractions. Dans certains cas, l'ajout de vitrages à contrôle solaire peut être nécessaire pour éviter la surchauffe en été et les pertes de chaleur en hiver.",
  },
  {
    heading: "Rénover la Toiture",
    content: "Changer ou améliorer la toiture peut également avoir un impact significatif sur le confort de la véranda. Une toiture en panneaux sandwich isolants peut offrir une meilleure isolation thermique et phonique. Les toitures mixtes, combinant panneaux isolants et vitrages, permettent d'apporter de la lumière tout en maintenant une isolation optimale.",
  },
  {
    heading: "Rénovation du Sol",
    content: "Rénover le sol de la véranda peut lui donner un aspect neuf et moderne. Les options populaires incluent le carrelage pour sa durabilité, le parquet pour son esthétique chaleureuse, et le PVC pour sa facilité d'installation et son coût abordable. Le choix du revêtement dépendra de l'utilisation prévue de la véranda et des préférences personnelles en matière de style.",
  }
],
conclusion: "La rénovation de véranda est une excellente occasion d'améliorer non seulement l'esthétique de votre maison mais aussi son efficacité énergétique et son confort. Pour des conseils personnalisés et un devis sur mesure pour votre projet à Lille, contactez PatekVerandas. Nous vous accompagnons à chaque étape, de l'évaluation initiale à la réalisation des travaux, pour garantir que votre nouvelle véranda réponde parfaitement à vos besoins et à vos attentes.",
invitation: "Pour en savoir plus sur les meilleures options de véranda et obtenir un devis personnalisé, contactez les experts de PatekVerandas, votre constructeur de vérandas à Lille. Que vous recherchiez une véranda sur-mesure ou une rénovation de véranda, nous sommes là pour vous aider à réaliser votre projet.",
buttonLabel: "Demander un devis",
image: renovation // Remplacez ceci par l'URL de votre image
};