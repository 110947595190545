import React from "react";
import { Routes as RoutesContainer, Route } from "react-router-dom";
import Home from "../pages/Home";
import Faq from "../pages/faq/Faq";
import Contact from "../pages/contact";
import Veranda from "../pages/Produits/veranda";
import Auvent from "../pages/Produits/Auvent";
import ProtectionSolaire from "../pages/Produits/ProtectionSolaire";
import ParoisVerre from "../pages/Produits/ParoisVerre";
import PorteVerre from "../pages/Produits/PorteVerre";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Apropos from "../pages/Apropos";
import NotFoundPage from "../pages/NotFoundPage";
import Devis from "../pages/devis";
import Catalogue from "../pages/Produits/Catalogue";
import TechnicalDetails from "../pages/TechnicalDetails";
import { QuoteProvider } from "../services/QuoteContext";
import ConfirmationPage from "../pages/ConfirmationPage";
import ArticlePage from "../pages/Articles/ArticlePage";
import ArticlesPage from "../pages/Articles/ArticlesPage";


const Routes = () => {
    return (
        <QuoteProvider>
            <RoutesContainer>
                <Route path="/" element={<Home/>}/>
                <Route path="/faq" element={<Faq/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/veranda" element={<Veranda/>}/>
                <Route path="/auvent" element={<Auvent/>}/>
                <Route path="/protection-solaire" element={<ProtectionSolaire/>}/>
                <Route path="/parois-coulissante" element={<ParoisVerre/>}/>
                <Route path="/portes-coulissante" element={<PorteVerre/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
                <Route path="/aboutus" element={<Apropos/>}/>
                <Route path="/devis" element={<Devis/>}/>
                <Route path="/catalogue" element={<Catalogue/>}/>
                <Route path="/details-technique" element={<TechnicalDetails/>} />
                <Route path="/confirmation" element={<ConfirmationPage />} />
                <Route path="/articles/:articleId" element={<ArticlePage/>} />
                <Route path="/articles" element={<ArticlesPage/>} />
                <Route path="/*" element={<NotFoundPage/>} />
            </RoutesContainer>
        </QuoteProvider>
    )
}

export default Routes;