import React from 'react';
import Protection1 from './../../assets/images/ProtectionS/Protection1.webp';
import Protection2 from './../../assets/images/ProtectionS/Protection2.webp';
import Protection3 from './../../assets/images/ProtectionS/Protection3.webp';
import Protection4 from './../../assets/images/ProtectionS/Protection4.webp';
import Protection5 from './../../assets/images/ProtectionS/Protection5.webp';
import Protection6 from './../../assets/images/ProtectionS/Protection6.webp';
import Protection7 from './../../assets/images/ProtectionS/Protection7.webp';
import ProtectionExpo1 from './../../assets/images/ProtectionS/ProtectionPres.webp';
import ProtectionExpo2 from './../../assets/images/ProtectionS/ProtectionPres2.webp';
import PageContent from '../../components/PageContent';
import ProtectionHeader from './../../assets/images/ProtectionS/ProtectionHeader.webp';
import { Helmet } from 'react-helmet';



const ProtectionImages = [
    Protection1,Protection2,Protection3,Protection4,Protection5,Protection6,Protection7
];

const ProtectionSolaire = () => {
    const preCarouselSection = {
      title: "L'équilibre parfait avec Patek Verandas",
      paragraphs: [
        "Chez Patek Verandas, nous conjuguons fonctionnalité et esthétique pour créer des espaces où l'ombre devient un élément de design. Nos protections solaires, véritables chefs-d'œuvre d'ingénierie, offrent un confort visuel et thermique incomparable.",
        "Elles sont conçues pour s'intégrer harmonieusement à l'architecture de votre demeure, tout en préservant la transparence et la luminosité. Avec Patek Verandas, profitez de votre extérieur en toute sérénité, la protection solaire se métamorphose en un art de vivre où chaque moment sous le soleil est un instant de pure élégance."
      ],
      imageUrl: ProtectionExpo1,
    };
  
    const postCarouselSection = {
      title: "L'Art de la protection solaire par Patek Verandas",
      paragraphs: [ 
        "Dans la quête d'un confort domestique supérieur, Patek Verandas transcende la notion de protection solaire pour en faire un statement de style et de sophistication. Nos auvents se dressent comme des sentinelles élégantes, gardiennes de votre intimité et de votre bien-être. Ils filtrent la clarté du jour pour créer une ambiance feutrée, invitant à la détente dans un cocon de fraîcheur.", 
        " L'élégance n'est plus confinée à l'intérieur; elle se déploie et s'épanouit dans chaque espace extérieur, enrichissant ainsi le langage architectural de votre demeure. Avec Patek Verandas, vivez l'été à l'abri des rayons, sous le signe du raffinement et de la personnalisation.",
      ],
      imageUrl: ProtectionExpo2,
    };
  
    return (
      <main>
        <Helmet>
    <title>Protection Solaire Efficace pour Votre Maison à Lille - Patek Verandas</title>
    <meta name="description" content="Solutions de protection solaire de qualité par Patek Verandas à Lille. Protégez votre espace avec style et efficacité en France et en Belgique." />
    </Helmet>

      <PageContent
        heroTitle="Nos Protections"
        heroImage={ProtectionHeader}
        preCarouselSection={preCarouselSection}
        postCarouselSection={postCarouselSection}
        carouselImages={ProtectionImages}
      />
      </main>
    );
  };

export default ProtectionSolaire;
