import React from 'react';

const Article = ({ title, introduction, sections, conclusion, invitation, buttonLabel, image }) => {
  return (
    <div className="bg-white text-gray-800 py-8 px-4 sm:px-8 lg:px-16">
      <div className="max-w-4xl mx-auto">
        {/* Titre de l'article aligné à gauche */}
        <h1 className="text-4xl font-bold text-blue-600 mb-4 text-left">{title}</h1>
        
        {/* Image principale */}
        {image && (
          <img
            src={image}
            alt={title}
            className="w-full h-auto object-cover rounded-lg mb-8"
            width="1200"
            height="800"
          />
        )}
        
        {/* Introduction alignée à gauche */}
        <section className="mb-8">
          <p className="text-lg leading-relaxed text-left">{introduction}</p>
        </section>

        {/* Sections de la liste en colonnes */}
        <section className="mb-8">
          {sections.map((section, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-2xl font-semibold text-blue-500 mb-2 text-left">{section.heading}</h2>
              <p className="text-base leading-relaxed text-left">{section.content}</p>
            </div>
          ))}
        </section>
        
        {/* Conclusion alignée à gauche */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-blue-500 mb-2 text-left">Conclusion</h2>
          <p className="text-lg leading-relaxed text-left">{conclusion}</p>
        </section>

        {/* Invitation et bouton avec texte aligné à gauche */}
        <section className="bg-gray-100 p-6 rounded-lg shadow-md">
          <p className="text-lg leading-relaxed mb-4 text-left">{invitation}</p>
          <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300">
            {buttonLabel}
          </button>
        </section>
      </div>
    </div>
  );
};

export default Article;
