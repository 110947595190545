import React from 'react';
import { useParams } from 'react-router-dom';
import Article from './../../components/Article';
import { articleAdvantages, articleTypes2024, articleMaintenance, articleEcoFriendly, articleChoose, articleRenovation } from './articles.jsx';
import { Helmet } from 'react-helmet';

const ArticlePage = () => {
  const { articleId } = useParams();
  const articles = {
    articleAdvantages,
    articleTypes2024,
    articleMaintenance,
    articleEcoFriendly,
    articleChoose,
    articleRenovation
  };
  
  const article = articles[articleId];

  return (
    <>
      <Helmet>
        <title>{article.title} - Patek Verandas</title>
        <meta name="description" content={article.introduction} />
      </Helmet>
      <Article {...article} />
    </>
  );
};

export default ArticlePage;
