import React from 'react';
import Verenda1 from './../../assets/images/Veranda/Veranda1.webp';
import Verenda2 from './../../assets/images/Veranda/Veranda2.webp';
import Verenda3 from './../../assets/images/Veranda/Veranda3.webp';
import Verenda4 from './../../assets/images/Veranda/Veranda4.webp';
import Verenda5 from './../../assets/images/Veranda/Veranda5.webp';
import Verenda6 from './../../assets/images/Veranda/Veranda6.webp';
import Verenda7 from './../../assets/images/Veranda/Veranda7.webp';
import VerandaHeader from './../../assets/images/Veranda/VerandaHeader.webp';
import VerandaExpo1 from './../../assets/images/Veranda/VerandaExpo1.webp';
import VerandaExpo2 from './../../assets/images/Veranda/VerandaExpo2.webp';
import PageContent from '../../components/PageContent';
import { Helmet } from 'react-helmet';



const verandasImages = [
    Verenda1,Verenda2,Verenda3,Verenda4,Verenda5,Verenda6,Verenda7
];

const Veranda = () => {
    const preCarouselSection = {
      title: "Invitez l'élégance dans votre demeure avec Patek Verandas",
      paragraphs: [
        " Chez Patek Verandas, nous sculptons l'espace et la lumière pour créer des vérandas a lille qui définissent l'élégance et la durabilité. Chaque projet est une œuvre d'art, façonnée avec précision pour transcender les limites entre l'intérieur et l'extérieur, et enrichir votre expérience de vie. Notre engagement envers l'excellence et le détail fait de chaque réalisation une signature de prestige et de confort."
      ],
      imageUrl: VerandaExpo1,
    };
  
    const postCarouselSection = {
      title: 'Une véranda a votre image',
      paragraphs: [ 
        "Chez Patek Verandas, l'art de la personnalisation atteint les sommets de l'excellence. Chaque veranda que nous créons est le fruit d'une alchimie délicate entre vos visions et notre savoir-faire artisanal. Dans notre atelier, les rêves prennent forme sous les mains de nos maîtres artisans.", 
        "Notre expertise dans la création sur mesure s'inspire de la tradition, où chaque pièce est une célébration de l'unicité et du luxe. Nous sommes dévoués à l'exécution parfaite de vos désirs, transformant le verre et le métal en espaces de vie où chaque mesure reflète votre personnalité et répond à vos besoins les plus précis. Avec Patek Verandas, votre maison s'habille d'une élégance sur mesure, un sanctuaire de bien-être taillé à l'aune de vos aspirations.",
      ],
      imageUrl: VerandaExpo2,
    };
  
    return (
      <main>
      <Helmet>
      <title>Vérandas à Lille de Haute Qualité - Patek Verandas</title>
      <meta name="description" content="Explorez notre gamme de vérandas a lille de qualité supérieure et dans le Nord. Patek Verandas offre des designs élégants et des installations rapides de veranda a lille et en Belgique" />
      </Helmet>
      <PageContent
        heroTitle="Nos Vérandas"
        heroImage={VerandaHeader}
        preCarouselSection={preCarouselSection}
        postCarouselSection={postCarouselSection}
        carouselImages={verandasImages}
      />
      </main>
    );
  };

export default Veranda;
