import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Hero from '../components/Hero';
import ContactImg from '../assets/images/Contact.webp'
import ContactUs from '../assets/styles/undrawContactUs.svg'
import MapIlustration from '../assets/styles/undrawdirection.svg'
import { Helmet } from 'react-helmet';
import whatsapp from '../assets/styles/whatsapp.svg'
import facebook from '../assets/styles/facebook.svg'
import instagram from '../assets/styles/instagram.svg'
import snapchat from '../assets/styles/snapchat.svg'
import phone from '../assets/styles/phone-icon.svg'






const Contact = () => {

  const [formSubmitted, setFormSubmitted] = useState(false); // New state for tracking submission
  const [captcha, setCaptcha] = useState({
    num1: Math.floor(Math.random() * 10),
    num2: Math.floor(Math.random() * 10)
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Veuillez entrer un nom valide')
      .required('Le nom est requis'),
    email: Yup.string()
      .email('Adresse email invalide')
      .required('L\'email est requis'),
    message: Yup.string()
      .required('Le message ne peut pas être vide'),
    captcha: Yup.number()
      .required('Veuillez résoudre le calcul pour prouver que vous n\'êtes pas un robot.')
      .oneOf([captcha.num1 + captcha.num2], 'Le résultat du calcul est incorrect.'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      captcha: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      fetch('https://formspree.io/f/xpzvwzpl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }).then(response => {
        if (response.ok) {
          setFormSubmitted(true); // Update state on successful submission
          formik.resetForm(); // Reset form fields
        };
      });
    },
  });

  

  return (
    <main>
    <Helmet>
    <title>Contactez Patek Verandas à Lille pour Vos Besoins de Véranda et d'Auvent</title>
    <meta name="description" content="Contactez Patek Verandas pour obtenir des vérandas de qualité à prix bas, des auvents et des solutions de protection solaire dans le Nord, la Belgique et la France." />
    </Helmet>
    <Hero image={ContactImg} titre="Contactez-nous" height="55" btn={false}/>
    <div className='bg-white'>
    <div className="contact-section py-10 flex flex-col md:flex-row md:justify-between m-auto pt-8  w-[80%]">
  <div className="mt-8 md:mb-0 md:w-1/2 container mx-auto px-6 text-left">
    <h2 className="text-2xl font-semibold mb-2">Contactez Patek Verandas</h2>
    <p className="text-gray-600 mb-6">Notre équipe d'experts est à votre écoute pour toute demande ou information. N'hésitez pas à nous contacter.</p>
    <div className="contact-info">
    <h2 className="uppercase mb-6 font-bold">Adresse</h2>
      <p className="text-gray-700">38 boulevard Carnot, Lille, France, 59800</p>
      <h3 className="uppercase mb-6 font-bold">Email</h3>
      <p><a className="text-gray-700" href="mailto:Service-commercial@patekverandas.fr">Service-commercial@patekverandas.fr</a></p>
      <p><a className="text-gray-700" href="mailto:contact@patekverandas.com">contact@patekverandas.com</a></p>
      <div className='flex justify-between w-[90%]'>
       <div> 
       <h4 className="uppercase mb-6 font-bold">Téléphone</h4>
          <p className="text-gray-700">Service Commercial
            <div className='flex flex-wrap items-center'>
              <a className="text-gray-700 flex" href="tel:+33782652280">+33 7 82 65 22 80 <img src={phone} alt="telephone" className="h-6 mx-2" width="24" height="24"/></a>
              <a href="https://wa.me/33782652280" target="_blank"><img src={whatsapp} alt="whatsapp" className="h-6 mx-2" width="24" height="24"/></a>
            </div>
          </p>
          <p className="text-gray-700">Service Technique
            <div className='flex flex-wrap items-center'>
              <a className="text-gray-700 flex" href="tel:+33750006427">+33 7 50 00 64 27<img src={phone} alt="telephone" className="h-6 mx-2" width="24" height="24"/></a>
              <a href="https://wa.me/33750006427" target="_blank"><img src={whatsapp} alt="whatsapp" className="h-6 mx-2" width="24" height="24"/></a>
            </div>
          </p>
          <p className="text-gray-700">Bureau <br></br><a className='text-gray-700' href="tel:+33980523008">+33 9 80 52 30 08</a></p>
        </div>
        <div>
        <h4 className="uppercase mb-6 font-bold">Réseaux Sociaux</h4>
          <ul className="list-none">
            <li className="mb-4"><a className='text-gray-700 flex' href="https://www.instagram.com/patekverandas/" target="_blank"><img src={facebook} alt="facebook"className="h-6 mr-2" width="24" height="24"/>Patek Verandas</a></li>
            <li className="mb-4"><a className='text-gray-700 flex' href="https://www.facebook.com/profile.php?id=61557733479722" target="_blank"><img src={instagram} alt="instagram" className="h-6 mr-2" width="24" height="24"/>Patekverandas</a></li>
            <li className="mb-4"><a className='text-gray-700 flex' href="https://www.snapchat.com/add/patekverandas" target="_blank" rel="noopener noreferrer"><img src={snapchat} alt="snapchat" className="h-6 mr-2" width="24" height="24"/>Patekverandas</a></li>
         </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="mt-8 md:mb-0 md:w-1/2">
    <img src={MapIlustration} alt="Illustration" className=' w-[75%]' width="1200" height="800"/>
  </div>
</div>
  <div className="contact-container flex flex-col md:flex-row md:justify-between bg-gradient-to-r from-blue-400 to-blue-600 p-4 sm:p-24">
  <div className="mt-8 md:mb-0 md:w-1/2">
    <img src={ContactUs} alt="Illustration" width="1200" height="800"/>
  </div>
  

  {/* Bloc pour le formulaire de contact */}
  <form onSubmit={formik.handleSubmit} className="contact-form md:w-[80] bg-white p-8 rounded-lg shadow-md mx-auto max-md:w-full ">
    <div className="mb-4">
      <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Nom</label>
      <input
        id="name"
        name="name"
        type="text"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out bg-gray-200"
        onChange={formik.handleChange}
        value={formik.values.name}
      />
      {formik.touched.name && formik.errors.name ? (
          <div className="error-message text-red-500 text-xs italic">{formik.errors.name}</div>
      ) : null}
    </div>

    <div className="mb-4">
      <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
      <input
        id="email"
        name="email"
        type="email"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out  bg-gray-200"
        onChange={formik.handleChange}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email ? (
          <div className="error-message text-red-500 text-xs italic">{formik.errors.email}</div>
      ) : null}
    </div>

    <div className="mb-6">
      <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message</label>
      <textarea
        id="message"
        name="message"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out bg-gray-200 h-36"
        onChange={formik.handleChange}
        value={formik.values.message}
      />
      {formik.touched.message && formik.errors.message ? (
          <div className="error-message text-red-500 text-xs italic">{formik.errors.message}</div>
      ) : null}
    </div>
  
  <div className="flex items-center justify-start max-md:flex-col-reverse">
  
  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline max-md:mt-4" aria-label='envoyer'>
    Envoyer
  </button>
  
  <label htmlFor="captcha" className="ml-4 flex items-center max-md:flex-col">
    Combien font {captcha.num1} + {captcha.num2} ?
    <input
      id="captcha"
      name="captcha"
      type="text"
      onChange={formik.handleChange}
      value={formik.values.captcha}
      className="ml-4 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out  bg-gray-200"
    />
  </label>
</div>
  </form>
</div>
{formSubmitted && (
        <div className="text-center py-4 bg-green-100 text-green-800">
          Votre message a été envoyé avec succès. Nous vous contacterons bientôt.
        </div>
      )}
</div>

    </main>
  );
};

export default Contact;
