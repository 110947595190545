import React from 'react';
import Hero from '../components/Hero';
import { Link } from 'react-router-dom';
import imgHeader from './../assets/images/AproposHeader.webp'
import { Helmet } from 'react-helmet';

const Apropos = () => {
  return (
    <main>
        <Helmet>
    <title>À Propos de Patek Verandas - Expert en Vérandas à Lille et au-delà</title>
    <meta name="description" content="Découvrez Patek Verandas, votre expert en vérandas, auvents et solutions de protection solaire à Lille, dans le Nord, en Belgique et en France. Qualité et innovation à votre service." />
        </Helmet>
        <Hero image={imgHeader} titre="A propos" height="40"/>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl text-gray-700 font-bold text-center mb-4">À propos de Patek Verandas</h1>
      <p className="text-lg text-gray-700 text-justify">
        Chez Patek Verandas, nous nous engageons à enrichir vos espaces de vie avec des vérandas de haute qualité à des prix imbattables. Notre mission est de fournir à nos clients des solutions élégantes et durables qui ajoutent une valeur inestimable à leurs propriétés.
      </p>
      <p className="text-lg text-gray-700 text-justify mt-4">
        Nous sommes fiers d'offrir nos services aux particuliers ainsi qu'aux professionnels, en veillant à ce que chaque véranda installée soit conforme aux normes européennes les plus strictes. Notre équipe d'experts s'assure que chaque projet est exécuté avec précision, en utilisant des matériaux de premier choix qui garantissent la sécurité et la satisfaction totale.
      </p>
      <p className="text-lg text-gray-700 text-justify mt-4">
        Avec Patek Verandas, optez pour l'excellence et l'assurance d'un investissement pérenne. Nous sommes à votre écoute pour toute demande et prêts à vous accompagner dans la réalisation de votre projet de véranda idéale.
      </p>
      <div className="text-center mt-8">
        <Link to="/contact" className="text-blue-600 hover:underline">Contactez-nous</Link>
    </div>
    </div>
    </main>
  );
};

export default Apropos;
