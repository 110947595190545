import React from 'react';
import ParoisVerre1 from './../../assets/images/ParoisVerre/ParoisVerre1.webp';
import ParoisVerre2 from './../../assets/images/ParoisVerre/ParoisVerre2.webp';
import ParoisVerre3 from './../../assets/images/ParoisVerre/ParoisVerre3.webp';
import ParoisVerre4 from './../../assets/images/ParoisVerre/ParoisVerre4.webp';
import ParoisVerre5 from './../../assets/images/ParoisVerre/ParoisVerre5.webp';
import ParoisVerre6 from './../../assets/images/ParoisVerre/ParoisVerre6.webp';
import ParoisVerre7 from './../../assets/images/ParoisVerre/ParoisVerre7.webp';
import ParoisVerrePres1 from './../../assets/images/ParoisVerre/ParoisVerrePres1.webp';
import ParoisVerrePres2 from './../../assets/images/ParoisVerre/ParoisVerrePres2.webp';
import PageContent from '../../components/PageContent';
import ParoisVerreHeader from './../../assets/images/ParoisVerre/ParoisVerreHeader.webp';
import { Helmet } from 'react-helmet';



const ParoisVerreImages = [
    ParoisVerre1,ParoisVerre2,ParoisVerre3,ParoisVerre4,ParoisVerre5,ParoisVerre6,ParoisVerre7
];

const ParoisVerre = () => {
    const preCarouselSection = {
      title: "Harmonie transparente, élégance intemporelle",
      paragraphs: [
        "Dans le sanctuaire de tranquillité qu'est votre demeure, Patek Verandas offre une symphonie de lumière et d'espace avec nos parois coulissantes en verre. Conçues pour les esthètes et les visionnaires, ces structures cristallines sont le pont entre le raffinement intérieur et la beauté naturelle de votre jardin. Non seulement elles élargissent votre horizon visuel, mais elles invitent également la nature à converser avec votre intérieur, apportant avec elle une lumière naturelle abondante et une sensation d'ouverture inégalée.",
        "Notre promesse est celle de l'excellence, chaque paroi coulissante est une promesse de qualité, une union de fonctionnalité et d'élégance. N'attendez plus pour transformer votre habitat avec l'élégance des parois coulissantes Patek Verandas - une touche de luxe qui vous rapproche des étoiles."
      ],
      imageUrl: ParoisVerrePres2,
    };
  
    const postCarouselSection = {
      title: "Une symphonie de verre et de lumière",
      paragraphs: [ 
        "Lorsque le crépuscule enveloppe votre espace de vie, les parois coulissantes en verre de Patek Verandas se transforment en toiles vivantes, capturant l'essence même du luxe discret. Chaque élément est une réflexion de la pureté et de l'élégance, offrant une vue imprenable sur la nature en toute saison. Ces chefs-d'œuvre architecturaux sont bien plus que de simples entrées ; ils sont des passages vers des mondes inexplorés, des sanctuaires de paix où chaque rayon de lumière amplifie la beauté de votre intérieur.", 
        "Vivre avec les parois coulissantes Patek Verandas, c'est choisir un dialogue continu avec l'environnement, un hommage à l'harmonie et à la sérénité. L'innovation et l'artisanat se rejoignent pour créer des espaces où le temps semble suspendu. Ne laissez pas passer l'occasion de vivre cette expérience visuelle et sensorielle unique ; procurez-vous les parois coulissantes en verre de Patek Verandas et ouvrez votre maison sur un monde de raffinement sans pareil.",
      ],
      imageUrl: ParoisVerrePres1,
    };
  
    return (
      <main>
      <Helmet>
      <title>Parois Coulissantes en Verre pour Intérieurs Modernes - Patek Verandas</title>
      <meta name="description" content="Optimisez votre espace avec les parois coulissantes en verre de Patek Verandas. Design et fonctionnalité à Lille, dans le Nord, et au-delà." />
      </Helmet>
      <PageContent
        heroTitle="Nos parois en verre"
        heroImage={ParoisVerreHeader}
        preCarouselSection={preCarouselSection}
        postCarouselSection={postCarouselSection}
        carouselImages={ParoisVerreImages}
      />
      </main>
    );
  };

export default ParoisVerre;
