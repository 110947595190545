import React, { useEffect, useState } from 'react';
import { BrainIcon } from '../assets/Icons/BrainIcon';
import { ConfianceIcon } from './../assets/Icons/ConfianceIcon';
import { FastIcon } from './../assets/Icons/FastIcon';
import { QualityIcon } from './../assets/Icons/QualityIcon';
import AccueilCard from './AcceuilCard';
import WorkCard from './WorkCard';
import undraw from './../assets/styles/undraw.svg';
import undrawQuestion from './../assets/styles/undrawQuestion.svg';
import VerandaNuitLarge from './../assets/images/VerandaNuit_large.webp';
import VerandaNuitSmall from './../assets/images/VerandaNuit_small.webp';
import AuventLarge from './../assets/images/Auvent_large.webp';
import AuventSmall from './../assets/images/Auvent_small.webp';
import ParoisCoulissantesVerreLarge from './../assets/images/ParoisCoulissantesVerre_large.webp';
import ParoisCoulissantesVerreSmall from './../assets/images/ParoisCoulissantesVerre_small.webp';
import PortesCoullisantesLarge from './../assets/images/PortesCoullisantes_large.webp';
import PortesCoullisantesSmall from './../assets/images/PortesCoullisantes_small.webp';
import { ToutLeMonde } from './../assets/Icons/ToutLeMonde';
import { Link } from 'react-router-dom';

const Features = () => {
  const [images, setImages] = useState({
    VerandaNuit: VerandaNuitLarge,
    Auvent: AuventLarge,
    ParoisCoulissantesVerre: ParoisCoulissantesVerreLarge,
    PortesCoullisantes: PortesCoullisantesLarge
  });

  useEffect(() => {
    const updateImages = () => {
      const width = window.innerWidth;
      if (width >= 768) {
        setImages({
          VerandaNuit: VerandaNuitLarge,
          Auvent: AuventLarge,
          ParoisCoulissantesVerre: ParoisCoulissantesVerreLarge,
          PortesCoullisantes: PortesCoullisantesLarge
        });
      } else {
        setImages({
          VerandaNuit: VerandaNuitSmall,
          Auvent: AuventSmall,
          ParoisCoulissantesVerre: ParoisCoulissantesVerreSmall,
          PortesCoullisantes: PortesCoullisantesSmall
        });
      }
    };

    const preloadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    };

    updateImages();
    window.addEventListener('resize', updateImages);

    // Preload images
    preloadImage(VerandaNuitLarge);
    preloadImage(VerandaNuitSmall);
    preloadImage(AuventLarge);
    preloadImage(AuventSmall);
    preloadImage(ParoisCoulissantesVerreLarge);
    preloadImage(ParoisCoulissantesVerreSmall);
    preloadImage(PortesCoullisantesLarge);
    preloadImage(PortesCoullisantesSmall);

    return () => {
      window.removeEventListener('resize', updateImages);
    };
  }, []);

  const projects = [
    {
      image: images.VerandaNuit,
      title: 'Veranda Moderne',
      description: "Inspirée par l'harmonie entre innovation et esthétique, notre véranda a lille est conçue pour transcender l'ordinaire. Dans l'esprit de Patek Verandas où chaque détail compte, nous avons méticuleusement veillé à ce que chaque élément, du cadre architectural en aluminium à l'intégration subtile de l'éclairage LED, incarne un luxe discret mais indéniable. Notre véranda est plus qu'une simple extension de l'espace de vie ; elle est une célébration de la lumière et du design, un sanctuaire où chaque moment est magnifié par la clarté du verre et l'élégance des lignes. Notre véranda a lille est le résultat d'un savoir-faire sans compromis, garantissant une expérience intemporelle et inégalée. Nous offrons non seulement une structure, mais une œuvre d'art vivante, où chaque seconde passée est un hommage à la contemplation et au confort. Bienvenue dans un monde où la tradition de l'excellence rencontre l'avant-garde du design domestique. Bienvenue dans votre véranda.",
    },
    {
      image: images.Auvent,
      title: 'Auvent',
      description: "Élégance, fonctionnalité et durabilité s'entremêlent dans la création de nos auvents, véritables chefs-d'œuvre architecturaux qui magnifient votre espace extérieur. Inspiré par l'artisanat sans compromis et le souci du détail propre à Patek Verandas, chaque auvent que nous installons est une expression de l'excellence. Notre auvent, illustré ici, incarne la sophistication avec sa structure en aluminium audacieuse et ses panneaux translucides qui filtrent la lumière naturelle. Comme les garde-temps légendaires, notre auvent est conçu pour transcender les modes et les époques, offrant une élégance intemporelle et une présence majestueuse qui complète votre demeure. Il est plus qu'une simple addition à votre habitat ; c'est une invitation à redécouvrir l'harmonie entre votre espace de vie et la nature, une promesse d'après-midis ensoleillés et d'étoiles contemplées sous un toit de verre. C'est une expérience de luxe au quotidien, où chaque instant devient un souvenir précieux. Bienvenue dans l'art de vivre signé Patek Verandas.",
    },
    {
      image: images.ParoisCoulissantesVerre,
      title: 'Parois coulissantes en verre',
      description: "Chaque paroi coulissante en verre que nous installons est une célébration de l'artisanat et de l'innovation, un hommage à la pureté du design et à l'élégance fonctionnelle. Dans l'esprit de Patek Verandas, où chaque création est un héritage, nos parois coulissantes en verre sont plus qu'un simple ajout esthétique; elles sont la symbiose parfaite entre intérieur et extérieur. Conçues pour s'effacer avec grâce, nos parois coulissantes offrent une transition sans effort entre vos espaces de vie, invitant la lumière et la beauté de votre jardin à se fondre dans le confort de votre intérieur. Ces vitrages rétractables sont l'expression d'un luxe discret et la promesse d'une expérience résidentielle sans précédent, où chaque vue devient une toile, chaque moment, un instant privilégié. Embrassez la quintessence du confort moderne avec Patek Verandas.",
    },
    {
      image: images.PortesCoullisantes,
      title: 'Portes coulissantes en verre',
      description: "Découvrez l'élégance intemporelle de nos portes coulissantes en verre, où chaque ouverture est une ode à la fluidité et chaque fermeture, un murmure de perfection. Nous mettons en œuvre un design raffiné et un savoir-faire inégalé pour transformer les espaces de vie. Ces panneaux de verre, délicatement suspendus, offrent un panorama sans obstruction, une invitation à faire entrer la splendeur du dehors à l'intérieur. Ils reflètent un engagement envers l'excellence, une promesse de qualité et d'harmonie qui s'intègre avec grâce à l'architecture de votre demeure. Les portes coulissantes que nous proposons ne sont pas de simples passages, mais des passerelles vers des moments de vie privilégiés, baignés de lumière et de sérénité. Avec Patek Verandas, franchissez le seuil d'un monde où le luxe est une expérience quotidienne.",
    }
  ];

  return (
    <div className="w-full">
      <section className="container mx-auto px-4 md:px-8 my-16">
        <div className="flex flex-wrap justify-center items-center -mx-2">
          <div className="grid xl:grid-cols-4 lg:grid-cols-2 gap-[5.5rem]">
            <AccueilCard icon={<BrainIcon width="50px" height="50px" />}>
              <h2 className="text-center text-customBlue font-bold text-sm mb-4 text-gradient">
                Notre Savoir-Faire
              </h2>
              <p className="text-center text-gray-600 font-jakartaSans font-medium text-[13px]">
                Forts de notre savoir-faire artisanal et de notre passion pour le design, nous nous engageons à apporter une touche d'élégance indémodable à votre demeure.
              </p>
            </AccueilCard>
            <AccueilCard icon={<QualityIcon width="50px" height="50px" />}>
              <h2 className="text-center text-customBlue font-bold text-sm mb-4 text-gradient">
                Engagement de Qualité
              </h2>
              <p className="text-center font-jakartaSans text-gray-600 font-medium text-[13px]">
                Notre promesse est simple : offrir les meilleurs prix du marché sans jamais compromettre la qualité ou le style.
              </p>
            </AccueilCard>
            <AccueilCard icon={<FastIcon width="50px" height="50px" />}>
              <h2 className="text-center text-customBlue font-bold text-sm mb-4 text-gradient">
                Réalisation Rapide
              </h2>
              <p className="text-center font-jakartaSans text-gray-600 font-medium text-[13px]">
                Chaque projet est une œuvre d'art en soi, conçu avec précision et réalisé avec une efficacité remarquable.
              </p>
            </AccueilCard>
            <AccueilCard icon={<ConfianceIcon width="50px" height="50px" />}>
              <h2 className="text-center text-customBlue font-bold text-sm mb-4 text-gradient">
                Votre Confiance, Notre Récompense
              </h2>
              <p className="text-center font-jakartaSans text-gray-600 font-medium text-[13px]">
                Votre confiance est notre plus belle récompense, et nous nous dédions à la perfection de chaque détail, pour une véranda a lille qui dépasse vos attentes.
              </p>
            </AccueilCard>
          </div>
        </div>
        <div className="mt-8">
          <div className="mb-4 pb-10 w-full bg-white relative flex flex-col justify-between items-center">
            <div className="relative h-[90px] w-[90px] bg-white shadow-2xl rounded-full -top-10 flex justify-center items-center">
              {<ToutLeMonde width="50px" height="50px" />}
            </div>
            <div className="flex flex-col px-6">
              <h2 className="text-center text-customBlue font-bold text-sm mb-4 text-gradient">
                Fourniseur de véranda a Lille pour les professionnels et les particuliers
              </h2>
              <p className="text-center font-jakartaSans text-gray-600 font-medium text-[13px]">
                Chez Patek Verandas, nous cultivons une tradition d'excellence qui s'étend à tous nos clients, qu'ils soient des particuliers en quête d'un havre de paix personnalisé ou des professionnels à la recherche d'une solution d'espace raffinée et fonctionnelle. Chaque projet est une œuvre d'art architecturale, conçue avec précision pour assurer une harmonie parfaite avec son environnement. Pour le particulier, une véranda a lille signée Patek Verandas est une extension de l'habitat, un lieu de vie supplémentaire où se mêlent lumière naturelle et design élégant. Pour le professionnel, c'est un engagement envers un espace de travail optimisé, où chaque détail est pensé pour allier esthétique et performance. Patek Verandas : une signature de prestige pour des espaces transformés, des moments sublimés.
              </p>
            </div>
            <div className="gradient w-full h-1 relative top-10"></div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8 mt-12 transition-opacity duration-500">
          {projects.map((project, index) => (
            <WorkCard key={index} image={project.image} title={project.title} description={project.description} />
          ))}
        </div>
      </section>
      <section className="w-full">
        <div className="flex flex-col md:flex-row items-center space-y-0 space-x-0 gradient p-8 mt-6">
          <div className="flex-shrink-0 flex flex-col items-center">
            <img src={undraw} alt="Faire un devis de veranda a lille par patek Verandas" className="w-full md:w-1/2" width="1200" height="800" />
          </div>
          <div className="mt-4 md:mt-0 md:ml-8">
            <h2 className="text-lg md:text-xl font-bold text-white">Prêt à transformer votre espace ?</h2>
            <p className="text-white my-2">Contactez-nous dès maintenant pour obtenir un devis gratuit et personnalisé pour votre projet.</p>
            <Link to="/devis"><button className="mt-4 bg-white text-customBlueLigth font-bold py-2 px-4 rounded-full hover:bg-blue-100">Demander un devis</button></Link>
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse items-center space-y-0 space-x-0 p-8 mt-6 mb-8">
          <div className="flex-shrink-0 flex flex-col items-center">
            <img src={undrawQuestion} alt="Visitez notre FAQ par Patek Verandas" className="w-full md:w-1/2" width="1200" height="800" />
          </div>
          <div className="mt-4 md:mt-0 md:mr-8">
            <h2 className="text-lg md:text-xl font-bold text-customBlueLigth">Des questions sur nos services ?</h2>
            <p className="text-customBlueLigth my-2">Visitez notre FAQ pour des réponses rapides et détaillées.</p>
            <Link to="/faq"><button className="mt-4 bg-customBlueLigth text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600">Visitez notre FAQ</button></Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
