import React, { useState } from 'react';

const Card = ({ image, compactTitle, compactSubtitle, expandedTitle, expandedContent }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="relative">
      <div className="bg-blue-500 rounded-t-3xl shadow-lg w-72 transform transition-all duration-500 hover:scale-105 mx-auto">
        <div className="relative overflow-hidden">
          <img className="w-full h-32 object-cover rounded-t-3xl" src={image} alt={compactTitle}
          width="1200"
          height="800" />
          <div className="flex flex-col items-center text-white mt-4">
            <h2 className="text-2xl font-bold mb-2">{compactTitle}</h2>
            <p className="text-lg mb-4">{compactSubtitle}</p>
            <button onClick={toggleExpand} className="bg-white text-blue-500 rounded-full p-2 transform transition-transform duration-300 hover:scale-110 focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={expanded ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`bg-blue-400 mt-4 p-4 text-white rounded-b-3xl w-72 mx-auto transition-all duration-500 ${expanded ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
        <h2 className="text-2xl font-bold mb-2">{expandedTitle}</h2>
        <div className="text-left">
          {expandedContent}
        </div>
      </div>
    </div>
  );
};

export default Card;
