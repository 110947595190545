import React, { useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuote } from '../services/QuoteContext';
import confirmationImage from '../assets/Icons/ConfirmationPageIcon.svg'


const ConfirmationPage = () => {
  const navigate = useNavigate();
  const {hasSubmittedQuote} = useQuote(true);

  useEffect(() => {
    console.log(hasSubmittedQuote)
    if (!hasSubmittedQuote) {
      navigate('/');
    } else {
      const timer = setTimeout(() => {
        navigate('/');
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [hasSubmittedQuote, navigate]);

  if (!hasSubmittedQuote) {
    return null; // Ou affichez un loader ou un message d'erreur
  }

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white rounded shadow-md max-w-md w-full text-center">
        <img src={confirmationImage} alt="Devis bien reçu" className="mx-auto mb-4 w-full" width="1200" height="800"/>
        <div className='p-4'>
        <h1 className="text-3xl font-semibold text-green-600">Merci pour votre demande de devis !</h1>
        <p className="mt-4 text-gray-700">
          Nous avons bien reçu votre demande et nous vous contacterons sous peu.
        </p>
        <p className="mt-2 text-gray-700">
          Vous serez redirigé vers la page d'accueil dans quelques secondes.
        </p>
        <div className="mt-8">
          <button
            onClick={() => navigate('/')}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Retour à l'accueil
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ConfirmationPage;
