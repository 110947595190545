import React from 'react';
import Auvent1 from './../../assets/images/Auvent/Auvent.webp';
import Auvent2 from './../../assets/images/Auvent/Auvent2.webp';
import Auvent3 from './../../assets/images/Auvent/Auvent3.webp';
import Auvent4 from './../../assets/images/Auvent/Auvent4.webp';
import Auvent5 from './../../assets/images/Auvent/Auvent5.webp';
import Auvent6 from './../../assets/images/Auvent/Auvent6.webp';
import Auvent7 from './../../assets/images/Auvent/Auvent7.webp';
import AuventExpo1 from './../../assets/images/Auvent/AuventPres.webp';
import AuventExpo2 from './../../assets/images/Auvent/AuventPres2.webp';
import PageContent from '../../components/PageContent';
import AuventHeader from './../../assets/images/Auvent/AuventHeader.webp';
import { Helmet } from 'react-helmet';



const auventImages = [
    Auvent1,Auvent2,Auvent3,Auvent4,Auvent5,Auvent6,Auvent7
];

const Auvent = () => {
    const preCarouselSection = {
      title: "L'Art de Vivre selon Patek Verandas",
      paragraphs: [
        " Dans l'harmonie de votre jardin, un auvent de Patek Verandas est plus qu'une simple structure ; c'est une promesse de moments intemporels. Conçu avec une minutie digne des grands maîtres, chaque auvent est une célébration de la précision et du raffinement. Sous sa couverture élégante, votre espace extérieur se transforme en une oasis de détente, un sanctuaire où chaque seconde est un luxe à savourer.",
        "Avec Patek Verandas, les frontières entre l'intérieur et l'extérieur s'estompent, vous invitant à redécouvrir la beauté tranquille de votre demeure, une élégance discrète mais indéniable qui s'inscrit dans la durée."
      ],
      imageUrl: AuventExpo1,
    };
  
    const postCarouselSection = {
      title: 'Votre Refuge Personnel Signé Patek Verandas',
      paragraphs: [ 
        "Dans chaque ligne épurée et chaque panneau translucide, Patek Verandas compose une symphonie de lumière et d'espace. Nos auvents en aluminium ne sont pas de simples extensions; ce sont des havres de paix conçus avec une précision, où le confort se mêle à l'esthétique contemporaine.", 
        "Un auvent Patek Verandas n'est pas seulement un choix, c'est une déclaration de style et de fonctionnalité, une promesse de moments inoubliables, bercés par la caresse du soleil et la brise douce de votre jardin. Ouvrez la porte à une élégance intemporelle et à un savoir-faire d'exception, où chaque détail est une célébration de l'art de vivre.",
      ],
      imageUrl: AuventExpo2,
    };
  
    return (
      <main>
      <Helmet>
      <title>Auvents Durables à Prix Compétitifs - Patek Verandas Lille</title>
      <meta name="description" content="Découvrez les auvents Patek Verandas à Lille, conçus pour la qualité et la durabilité. Idéals pour le Nord, la Belgique et la France." />
      </Helmet>
      
      <PageContent
        heroTitle="Nos Auvents"
        heroImage={AuventHeader}
        preCarouselSection={preCarouselSection}
        postCarouselSection={postCarouselSection}
        carouselImages={auventImages}
      />
      </main>
    );
  };

export default Auvent;
