import React, { useRef, useState } from 'react';
import './faq.css';
import FaqImage from './../../assets/images/FaqImage.webp'
import Hero from '../../components/Hero';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


// Imaginons que vous ayez un tableau d'objets contenant les données de votre FAQ
const faqData = [
    {
      question: "Quelle est la différence entre un auvent, un jardin d'hiver, une serre et une véranda ?",
      answer: "Un auvent et une véranda sont des synonymes pour un espace extérieur couvert attaché à une maison, soutenu par deux piliers en aluminium ou en bois et recouvert de polycarbonate ou de verre. Un jardin d'hiver est une extension 'non isolée' d'une maison, similaire à un auvent ou à une véranda, mais avec des parois (en verre) coulissantes permettant de fermer complètement l'espace. Un jardin d'hiver n'est pas une partie intégrante de la maison. Bien qu'un jardin d'hiver ressemble à une serre, la principale différence est qu'une serre est une extension isolée de la maison, ce qui augmente considérablement les coûts d'achat et la complexité. Patek Verandas se concentre exclusivement sur les auvents, vérandas et jardins d'hiver dans sa gamme de produits."
    },
    {
      question: "Quels sont les avantages d'avoir un auvent en aluminium ?",
      answer: "Un auvent offre un espace extérieur couvert où vous pouvez vous asseoir, protégé du soleil et de la pluie. C'est un bel ajout à votre maison et protège vos meubles de jardin des intempéries. Les auvents en aluminium sont moins chers que d'autres matériaux et offrent une construction solide qui dure longtemps avec peu d'entretien. Vous pouvez ainsi profiter de votre investissement pendant de nombreuses années sans avoir à le remplacer."
    },
    {
      question: "Est-il possible d'ajouter un mur de verre à mon auvent à une date ultérieure ?",
      answer: "Il est possible d'ajouter un mur de verre à un auvent existant à une date ultérieure. Nous vous recommandons de contacter un spécialiste pour cela."
    },
    {
        question: "Comment entretenir un auvent ?",
        answer: "Un auvent nécessite généralement peu d'entretien. Cependant, nous recommandons de le nettoyer environ deux fois par an pour enlever la saleté, ce qui améliore son aspect esthétique et prolonge sa durée de vie. Pour le nettoyage, utilisez une éponge ou un chiffon doux. Le cadre en aluminium peut être nettoyé facilement avec de l'eau et un savon doux, puis rincé abondamment. La couverture du toit peut être nettoyée de la même manière, mais faites attention car elle peut facilement être rayée. Assurez-vous également que la gouttière soit régulièrement nettoyée des feuilles et des débris pour éviter le blocage de l'évacuation de l'eau."
      },
      {
        question: "La formation de condensation dans le polycarbonate est-elle normale ?",
        answer: "En raison de la perméabilité à la vapeur d'eau des plastiques, il peut se former de la condensation dans les plaques de polycarbonate. La survenue de ce problème de condensation dépend des conditions météorologiques et de la situation de l'installation. Des facteurs tels que la température, la différence de température entre l'intérieur et l'extérieur, l'humidité absolue, l'humidité relative, le vent et le rayonnement solaire jouent un rôle. Nous soulignons que la formation de condensation dans les plaques alvéolaires n'affecte pas négativement la fonction et la qualité des plaques. De plus, cela peut se produire temporairement sous différentes formes, à l'intérieur comme à l'extérieur des plaques individuelles. Si vous avez des doutes ou des questions sur ce problème, n'hésitez pas à nous contacter."
      },
      {
        question: "Combien de temps dure un auvent ?",
        answer: "Un auvent peut durer de nombreuses années avec un bon entretien. Cependant, de nombreux facteurs peuvent influencer sa durée de vie, il n'est donc pas possible de fournir une garantie précise."
      },
      {
        question: "Les parois coulissantes en verre de Patek Verandas peuvent-elles être installées partout ?",
        answer: "Les parois coulissantes en verre de Patek Verandas sont conçues pour être flexibles et peuvent donc être installées sous n'importe quel auvent de n'importe quelle marque."
      },
      {
        question: "Pouvez-vous me conseiller sur l'entretien et le nettoyage de ma paroi coulissante en verre ?",
        answer: "Vérifiez régulièrement si les fixations à vis sont bien serrées et resserrez-les si nécessaire. Assurez-vous que les panneaux de verre sont parfaitement ajustés en hauteur pour éviter une usure prématurée. Nettoyez bien le système de rails inférieurs pour prévenir la contamination et prolonger la durée de vie. Enlevez la saleté et les impuretés des profils en aluminium et des panneaux de verre avec de l'eau tiède et un produit de nettoyage neutre. Rincez bien la paroi coulissante en verre avant la première utilisation avec de l'eau propre. Pour le nettoyage, utilisez un matériau doux, une éponge et beaucoup d'eau. N'utilisez pas de produits abrasifs ou de solvants agressifs, mais des solvants tels que le liquide vaisselle et le Glassex sont autorisés pour éliminer la saleté grasse. N'utilisez jamais un nettoyeur haute pression."
      },
      {
        question: "Un auvent peut-il être considéré comme une dépendance ?",
        answer: "Un auvent est une construction destinée à protéger votre maison ou jardin des intempéries telles que la pluie, le soleil et le vent. Il existe différents types d'auvents, allant de simples abris à de grandes vérandas. Il y a parfois débat pour savoir si un auvent peut être considéré comme une dépendance. Selon la définition d'une dépendance, c'est un bâtiment qui est relié au bâtiment principal mais qui ne répond pas aux critères d'un logement. Cela signifie qu'un auvent n'est pas nécessairement considéré comme une dépendance, cela dépend de sa taille et de son usage. Si un auvent est assez grand pour être utilisé comme espace de vie ou s'il est conçu intentionnellement comme une dépendance, il peut alors être considéré comme tel et des autorisations peuvent être nécessaires avant sa construction."
    },
    {
        question: "Quel est le délai estimé entre la demande et la livraison de mon auvent ?",
        answer: "Dans les 14 jours suivant votre commande, l'auvent est prêt dans votre jardin. Patek Verandas garantit le meilleur service, conseils, astuces, service de mesure gratuit et installation. Demandez un devis ou commencez une discussion WhatsApp avec nous et dans 14 jours, vous profiterez de votre propre auvent."
    },
    {
        question: "Dois-je verser un acompte ?",
        answer: "Oui, un acompte est requis pour l'installation de votre véranda. Pour confirmer votre commande, un acompte de 30% doit être versé. Ce montant initial nous permet de débuter la préparation et l'installation de votre véranda. Une fois l'installation terminée à votre satisfaction, vous serez invité à payer le solde restant."
    },
    {
        question: "Votre service comprend-il l'installation et le sur-mesure ?",
        answer: "Oui ! Lorsque Patek Verandas réalise votre auvent, nous nous occupons de tout, de l'idée à la réalisation."
    }
  ];
  const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
  
    const toggle = () => {
      setIsOpen(!isOpen);
    };

  const maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';

  return (
    <main>
    <Helmet>
    <title>FAQ - Vos Questions sur les Vérandas et Plus Répondues - Patek Verandas</title>
    <meta name="description" content="Trouvez les réponses à vos questions sur les vérandas, auvents et portes coulissantes chez Patek Verandas. Des informations claires pour nos clients de Lille, Belgique et France." />
    </Helmet>
    <div className="max-w-md w-full p-4 bg-white rounded-lg shadow-md m-2 flex flex-col items-center justify-center">
      <div className="flex justify-between items-center w-full" onClick={toggle}>
        <h4 className="text-lg text-gray-700 font-semibold text-center flex-grow">{question}</h4>
        <button className={`h-12 w-12 rounded-full flex items-center justify-center ${isOpen ? 'bg-blue-200' : 'gradient'} text-xl text-white`} onClick={toggle}>
          {isOpen ? '-' : '+'}
        </button>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: maxHeight }}
        className="transition-max-height duration-500 ease-in-out w-full overflow-hidden"
      >
        <p className="mt-2 text-gray-700 text-center">{answer}</p>
      </div>
    </div>
    </main>
  );
};

const Faq = () => {
  return (
    <main>
    <Hero image={FaqImage} height={55} titre={"Questions fréquentes"}></Hero>
    <div className="container mx-auto p-4 mt-6 mb-6">
      <div className="flex flex-wrap justify-center -m-2">
        {faqData.map((faq, index) => (
          <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <div className="text-center mt-8"> {/* Ajoutez une marge en haut */}
        <Link to="/contact" className="button-gradient text-white">
          Poser une question
        </Link>
      </div>
    </div>
    </main>
  );
};

export default Faq;
