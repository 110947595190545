import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Hero = (props) => {
  const heroHeight = props.height ? `${props.height}vh` : '100vh';
  const Titre = props.titre ? props.titre : "Construisons vos rêves, un verre à la fois";
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = props.image;
    img.onload = () => setIsImageLoaded(true);
  }, [props.image]);

  return (
    <div className="flex justify-center items-center bg-cover bg-center h-screen text-white w-full" style={{ backgroundImage: isImageLoaded ? `url(${props.image})` : 'none', height: heroHeight }}>
      <div className="text-center">
        <h1 className="text-5xl font-bold mt-4 mb-8 text-shadow">{Titre}</h1>
        <div>
          {props.btn && (
            <div className='max-md:flex-col max-md:flex'>
              <Link to="/aboutus" className="button-gradient text-white">En savoir plus</Link>
              <Link to="/devis" className="max-md:m-2 bg-white text-customBlue bg-opacity-70 hover:bg-opacity-80 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-10 py-2.5 mr-2 mb-2 transition duration-300 ease-in-out">Faire un devis</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
