// App.js
import React from 'react';
import Routes from './routes/Routes';
import Layout from './components/Layout';



function App() {
  return (
      <Layout>
        <Routes />
      </Layout>
  );
}

export default App;
