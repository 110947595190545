import React from 'react';
import PorteVerre1 from './../../assets/images/PorteVerre/PorteVerre1.webp';
import PorteVerre2 from './../../assets/images/PorteVerre/PorteVerre2.webp';
import PorteVerre3 from './../../assets/images/PorteVerre/PorteVerre3.webp';
import PorteVerre4 from './../../assets/images/PorteVerre/PorteVerre4.webp';
import PorteVerre5 from './../../assets/images/PorteVerre/PorteVerre5.webp';
import PorteVerre6 from './../../assets/images/PorteVerre/PorteVerre6.webp';
import PorteVerre7 from './../../assets/images/PorteVerre/PorteVerre7.webp';
import PorteVerre8 from './../../assets/images/PorteVerre/PorteVerre8.webp';
import PorteVerre9 from './../../assets/images/PorteVerre/PorteVerre9.webp';
import PorteVerrePres1 from './../../assets/images/PorteVerre/PorteVerrePres1.webp';
import PorteVerrePres2 from './../../assets/images/PorteVerre/PorteVerrePres2.webp';
import PageContent from '../../components/PageContent';
import PorteVerreHeader from './../../assets/images/PorteVerre/PorteVerreHeader.webp';
import { Helmet } from 'react-helmet';



const PorteVerreImages = [
    PorteVerre1,PorteVerre2,PorteVerre3,PorteVerre4,PorteVerre5,PorteVerre6,PorteVerre7,PorteVerre8,PorteVerre9
];

const PorteVerre = () => {
    const preCarouselSection = {
      title: "Les portes coulissantes Patek Verandas",
      paragraphs: [
        "Au cœur de la conception de Patek Verandas réside une quête incessante de perfection. Nos portes coulissantes en verre incarnent cette aspiration, alliant fonctionnalité et esthétique avec une grâce sans effort. Chaque ouverture est une invitation à la clarté et à l'ouverture, un passage fluide entre les mondes intérieur et extérieur.",
        "Ces structures minimalistes mais robustes sont le fruit d'une ingénierie précise, où chaque détail est pensé pour améliorer votre confort et votre bien-être. La facilité de mouvement des portes offre un accès sans encombre, tandis que la transparence du verre étend votre horizon et apporte la splendeur du jardin dans votre intérieur. Procurez-vous nos portes coulissantes en verre et donnez à votre demeure une touche de modernité intemporelle, où la lumière et la vie coulent en harmonie."
      ],
      imageUrl: PorteVerrePres1,
    };
  
    const postCarouselSection = {
      title: "Votre panorama avec Patek Verandas",
      paragraphs: [ 
        "Dans l'atelier de Patek Verandas, nous façonnons bien plus que des structures : nous créons des expériences. Nos parois coulissantes en verre sont des chefs-d'œuvre de finesse et de fonctionnalité, conçues pour révéler la beauté sans filtre de votre environnement tout en protégeant ce qui vous est cher.", 
        "Chaque panneau de verre est une fenêtre sur le monde, un équilibre délicat entre protection et exposition, privé et ouvert. Lorsque vous choisissez Patek Verandas, vous choisissez une perspective sans limites, où chaque moment de la journée enrichit votre espace de vie d'une lumière naturelle et d'une vue imprenable. Donnez à votre foyer l'élégance des lignes épurées et la tranquillité d'un design qui se fond dans la nature environnante. Avec nos parois coulissantes, le passage entre les espaces devient un geste poétique, une invitation à vivre pleinement chaque instant dans un cadre de pureté visuelle.",
      ],
      imageUrl: PorteVerrePres2,
    };
  
    return (
      <main>
      <Helmet>
      <title>Portes Coulissantes en Verre Élégantes à Lille - Patek Verandas</title>
      <meta name="description" content="Patek Verandas offre des portes coulissantes en verre de qualité pour une élégance moderne à Lille, dans le Nord, en Belgique et en France." />
      </Helmet>
      <PageContent
        heroTitle="Nos parois en verre"
        heroImage={PorteVerreHeader}
        preCarouselSection={preCarouselSection}
        postCarouselSection={postCarouselSection}
        carouselImages={PorteVerreImages}
      />
      </main>
    );
  };

export default PorteVerre;
