import React from 'react';
import Navigation from './Navigation ';
// Supposons que vous avez un logo en tant qu'image SVG ou PNG

const Header = () => {
  return (
    <header className="bg-customWhite bg-opacity-80 backdrop-filter backdrop-blur-lg shadow-md py-4 sticky top-0 z-10">
        <Navigation />
    </header>
  );
};

export default Header;
