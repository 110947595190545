import React from 'react';
import { Helmet } from "react-helmet";

const Catalogue = () => {
  return (
    <main>
      <Helmet>
        <title>Catalogue Patek Verandas - Vérandas a lille de Qualité à Prix Bas à Lille et Nord - Livraison Rapide de véranda a lille et Belgique</title>
        <meta name="description" content="Découvrez nos vérandas a Lille signée Patek Verandas de haute qualité à des prix bas et compétitifs. Service rapide et fiable de veranda a Lille, dans le Nord, ainsi qu'en Belgique. Conçues pour la durabilité et l'esthétique par Patek Verandas - Patek Verandas." />
      </Helmet>
      <div className="Catalogue" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{
          position: 'relative',
          width: '80%',
          height: '80%',
          boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
          marginTop: '1.6em',
          marginBottom: '0.9em',
          overflow: 'hidden',
          borderRadius: '8px',
          willChange: 'transform'
        }}>
          <iframe
            loading="lazy"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0
            }}
            src="https://www.canva.com/design/DAGEoiYFBFA/-EFqKcVPtWSDfLJkh2X-ew/view?embed"
            allowFullScreen
            allow="fullscreen">
          </iframe>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '1em' }}>
        <a
          href="https://www.canva.com/design/DAGEoiYFBFA/-EFqKcVPtWSDfLJkh2X-ew/view?utm_content=DAGEoiYFBFA&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
          target="_blank"
          rel="noopener noreferrer">
          Blue and Orange Modern Simple Minimalist Furniture Product Catalog Booklet
        </a>
        par Soufiane Rechia
      </div>
    </main>
  );
};

export default Catalogue;
