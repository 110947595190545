// Chatbot.jsx
import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, ChatBubbleLeftEllipsisIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import './../utils/Chatbot.css'

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closing, setClosing] = useState(false); // État pour gérer la fermeture avec animation
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([
        { sender: 'bot', text: 'Bonjour ! Comment puis-je vous aider aujourd\'hui ?' }
      ]);
    }
  }, [isOpen, messages.length]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const toggleChat = () => {
    if (isOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setClosing(false);
      }, 300); // Durée de l'animation de fermeture
    } else {
      setIsOpen(true);
    }
  };

  const handleMessageSend = async () => {
    const userMessage = input.trim();
    if (userMessage) {
      const newMessages = [...messages, { sender: 'user', text: userMessage }];
      setMessages(newMessages);
      setInput('');

      try {
        const response = await query({ "in-0": userMessage, "user_id": "user123" });
        console.log('Response from API:', response);

        // Accéder correctement au message du bot
        const botMessage = response.outputs["out-0"];
        if (botMessage) {
          setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: botMessage }]);
        } else {
          console.error('No bot response received');
        }
      } catch (error) {
        console.error('Error querying the bot:', error);
      }
    }
  };

  const query = async (data) => {
    const response = await fetch(
      "https://api.stack-ai.com/inference/v0/run/adbcb9c6-885d-4661-930b-445a5a887267/66828672b2195d15a1cd062b",
      {
        headers: {
          'Authorization': 'Bearer b6ac4fcf-0059-44b4-b278-27e02ded07b3', // Assurez-vous que le token est correct
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    return result;
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen && !closing && (
        <button
          className="bg-blue-500 text-white p-4 rounded-full shadow-lg flex items-center"
          onClick={toggleChat}
          title="chat avec patekverandas"
        >
          <ChatBubbleLeftEllipsisIcon className="h-6 w-6" />
        </button>
      )}
      {(isOpen || closing) && (
        <div className={`chatbot-container ${closing ? 'chatbot-container-closing' : ''} bg-white border rounded-lg shadow-lg w-80 h-[28rem] flex flex-col`}>
          <div className="flex justify-between items-center bg-blue-500 text-white px-2 py-1 rounded-t-lg">
            <div className="flex items-center">
              <ChatBubbleLeftEllipsisIcon className="h-6 w-6 mr-2" />
              <h2 className="text-lg font-semibold">PatekAssitance</h2>
            </div>
            <button onClick={toggleChat} className="p-1 rounded-full hover:bg-blue-600">
              <XMarkIcon className="h-6 w-6 text-black" />
            </button>
          </div>
          <div className="flex-1 overflow-y-auto mb-4 p-2 bg-gray-50">
            {messages.map((message, index) => (
              <div key={index} className={`my-2 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                <div className={`p-2 rounded-lg max-w-sm ${message.sender === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>
                  {message.text}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="flex p-2 border-t">
            <input
              type="text"
              className="border p-2 rounded-l-full flex-1 focus:outline-none"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' ? handleMessageSend() : null}
              placeholder="Écrire un message..."
            />
            <button
              className="bg-blue-500 text-white p-2 rounded-r-full"
              onClick={handleMessageSend}
            >
              <PaperAirplaneIcon className="h-6 w-6 transform rotate-90" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
