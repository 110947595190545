// WhatsAppButton.js
import React from 'react';

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/33750006427" // Remplacez par votre numéro WhatsApp
      className="fixed bottom-4 left-4 z-50"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Appelez sur Whatsapp PatekVerandas"
    >
      <div className="bg-green-500 p-3 rounded-full shadow-lg flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          fill="white"
          className="h-8 w-8"
        >
          <path d="M380.9 97.1C339.1 55.4 283.2 32 224 32 98.8 32 0 130.8 0 256c0 45.1 12 89.1 34.8 127.5L1.5 480c-1.4 5.5-.1 11.3 3.7 15.2 2.9 3 6.8 4.8 10.9 4.8 1.4 0 2.9-.2 4.3-.5l100.3-26.3c37 20 78.4 30.7 120.9 30.7 125.2 0 224-98.8 224-224 0-59.2-23.1-114.9-65.1-157zm-159.9 333c-37.4 0-73.5-10-104.5-29.1l-7.5-4.5-61.5 16.1 16.4-60.1-4.9-7.7C47.1 303.3 38 280.3 38 256c0-102.1 83-185 186-185 49.5 0 96 19.3 130.9 54.1 34.8 34.8 54.1 81.3 54.1 130.9-.1 102.1-83 185.1-186 185.1zM335.1 303c-5.5-2.8-32.5-16-37.5-17.8-5-1.8-8.7-2.8-12.5 2.8-3.7 5.5-14.3 17.8-17.5 21.5-3.2 3.7-6.5 4.1-12 1.4-5.5-2.8-23.2-8.5-44.3-27-16.4-14.6-27.5-32.5-30.8-37.8-3.2-5.5-.3-8.5 2.5-11.3 2.6-2.6 5.5-6.5 8.2-9.7 2.7-3.2 3.7-5.5 5.5-9.1 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.3-17.2-41.8-4.5-10.8-9.2-9.4-12.5-9.5-3.2-.2-6.9-.2-10.6-.2s-9.7 1.4-14.8 6.9c-5 5.5-19.4 19-19.4 46.3s19.9 53.7 22.7 57.4c2.8 3.7 39.2 59.8 94.8 83.7 35.2 15.2 49 16.6 66.8 14.1 10.7-1.6 32.5-13.2 37.2-25.9 4.6-12.8 4.6-23.7 3.2-25.9-1.3-2-5-3.2-10.5-5.9z"/>
        </svg>
      </div>
    </a>
  );
};

export default WhatsAppButton;
