import React from 'react';
import Hero from '../components/Hero';
import Card from '../components/Card';
import heroImage from '../assets/images/Details.webp';
import aluminiumImage from '../assets/images/AluminiumCard.webp';
import verreImage from '../assets/images/VerreCard.webp';
import polycarbonateImage from '../assets/images/PolycarbonateCard.webp';
import { Helmet } from "react-helmet";
import garantie5 from './../assets/Icons/Garantie5.svg'
import garantie10 from './../assets/Icons/Garantie10.svg'
import EC from './../assets/Icons/EC.svg'



const TechnicalDetails = () => {
    return (
      <main className="bg-gray-100 text-gray-900">
        <Helmet>
          <title>Détails Techniques des Matériaux - Patek Verandas</title>
          <meta name="description" content="Découvrez les détails techniques des matériaux utilisés par Patek Verandas pour la confection de vérandas de haute qualité." />
        </Helmet>
        <Hero image={heroImage} btn={false} titre="Détails Techniques" height="55" />
        <section className="px-8 py-16">
          <div className="max-w-7xl mx-auto">
            <div className='flex justify-around w-full items-center'>
              <img src={garantie5} alt="Garantie fabricant 5 ans" className="w-1/5 h-auto" width="1200" height="800"/>
              <img src={EC} alt="Certificat européen" className="w-1/5 h-auto" width="1200" height="800"/>
              <img src={garantie10} alt="Garantie 10 ans" className="w-1/5 h-auto" width="1200" height="800"/>
            </div>
            <h1 className="text-5xl font-extrabold text-gray-900 mb-12 text-center">Détails Techniques des Matériaux</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
              <Card
                image={aluminiumImage}
                compactTitle="Aluminium"
                compactSubtitle="Durable et résistant"
                expandedTitle="Aluminium"
                expandedContent={
                  <div>
                    <p>Nos couvertures de terrasse sont fabriquées en aluminium 6060-&6 (un aluminium 25% plus résistant que le standard). Les auvents durent plus longtemps car ils ne sont pas affectés par l’humidité ou les rayons UV grâce au revêtement en poudre spécial.</p>
                    <p className="mt-4">Nous avons la verrière Classic avec des courbes intemporelles jusqu’à la gouttière et nous avons une version moderne avec des coins droits et des lignes nettes jusqu’à la gouttière.</p>
                    <ul className="list-disc list-inside mt-4">
                      <li>Aluminium 6060-T6</li>
                      <li>Revêtement de qualité supérieure</li>
                      <li>Peu d'entretien</li>
                      <li>80 kg par m2.</li>
                      <li>Charge de neige selon la norme NEN 6702.</li>
                      <li>Marquage CE et donc conforme à toutes les normes de construction européennes.</li>
                    </ul>
                  </div>
                }
              />
              <Card
                image={verreImage}
                compactTitle="Toit en verre"
                compactSubtitle="Luxueux et durable"
                expandedTitle="Toit en verre"
                expandedContent={
                  <div>
                    <p>Que vous choisissiez un toit en polycarbonate ou une verrière, une chose prime, c’est la qualité.</p>
                    <h3 className="mt-4">Verre trempé et feuilleté (4-4-2)</h3>
                    <ul className="list-disc list-inside mt-4">
                      <li>8,76 mm d'épaisseur</li>
                      <li>98 cm de large</li>
                      <li>Un look luxueux</li>
                      <li>Choix de verre transparent ou de verre de lait</li>
                    </ul>
                    <h4 className="mt-4">Verre clair</h4>
                    <ul className="list-disc list-inside mt-2">
                      <li>88 % Transmission de la lumière</li>
                      <li>85% Transmission d'énergie</li>
                      <li>36 db Réduction du bruit</li>
                      <li>8,76mm</li>
                    </ul>
                    <h4 className="mt-4">Verre de lait</h4>
                    <ul className="list-disc list-inside mt-2">
                      <li>63% Transmission de la lumière</li>
                      <li>52% Transmission d'énergie</li>
                      <li>36 db Réduction du bruit</li>
                      <li>8,76mm</li>
                    </ul>
                  </div>
                }
              />
              <Card
                image={polycarbonateImage}
                compactTitle="Toit en polycarbonate"
                compactSubtitle="Robuste et polyvalent"
                expandedTitle="Toit en polycarbonate"
                expandedContent={
                  <div>
                    <p>Que vous choisissiez un toit en polycarbonate ou une verrière, une chose prime, c’est la qualité.</p>
                    <ul className="list-disc list-inside mt-4">
                      <li>Plastique dur et solide</li>
                      <li>16 mm d'épaisseur</li>
                      <li>Structure en nid d'abeille</li>
                      <li>Choix entre transparent et opale</li>
                      <li>98 cm de large</li>
                    </ul>
                    <h4 className="mt-4">Polycarbonate clair</h4>
                    <ul className="list-disc list-inside mt-2">
                      <li>70% Transmission de la lumière</li>
                      <li>62% Transmission d'énergie</li>
                      <li>21 db Réduction du bruit</li>
                      <li>16 mm d'épaisseur</li>
                    </ul>
                    <h4 className="mt-4">Polycarbonate opale</h4>
                    <ul className="list-disc list-inside mt-2">
                      <li>55% Transmission de la lumière</li>
                      <li>54% Transmission d'énergie</li>
                      <li>21 db Réduction du bruit</li>
                      <li>16 mm d'épaisseur</li>
                    </ul>
                  </div>
                }
              />
            </div>
          </div>
        </section>
      </main>
    );
  };
  
  export default TechnicalDetails;