import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import Home from '../assets/styles/homepage-icon.svg';
import produits from '../assets/styles/produits.svg';
import faq from '../assets/styles/faq.svg';
import devis from '../assets/styles/devis.svg';
import contact from '../assets/styles/contact.svg';
import logo from '../assets/styles/logo.svg'
import Hamburger from '../assets/styles/Hamburger.svg'
import phone from '../assets/styles/phone-icon.svg'
import articles from '../assets/styles/articles.svg'

const Navigation = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRefMobile = useRef(null);
    const dropdownRefDesktop = useRef(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
      function handleOutsideClickOrTouch(event) {
        if ((dropdownRefMobile.current && !dropdownRefMobile.current.contains(event.target)) &&
      (dropdownRefDesktop.current && !dropdownRefDesktop.current.contains(event.target))) {
    setDropdownOpen(false);
  }
      }
    
      document.addEventListener('touchstart', handleOutsideClickOrTouch);
      document.addEventListener('mousedown', handleOutsideClickOrTouch);
    
      return () => {
        document.removeEventListener('touchstart', handleOutsideClickOrTouch);
        document.removeEventListener('mousedown', handleOutsideClickOrTouch);
      };
    }, [dropdownRefMobile,dropdownRefDesktop]);
    


    const handleCloseMobileMenu = () => {
      setMobileMenuOpen(false);
    };


    return (
      <div>
      <div className="md:hidden flex items-center justify-around">
        <button className="bg-transparent"onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          <img src={Hamburger} alt="Menu" className="h-6 mr-2" width="24" height="24"/>
        </button>
        <div className="flex justify-around">
        <Link to="/" className="flex items-center mr-4">
          <img src={logo} className="h-10" alt="logo Patek Verandas veranda a lille" width="80" height="40"/>
        </Link>
        <Link to="tel:+33750006427" className="flex flex-col">
          <img src={phone} alt="telephone" className="h-4 mr-1" width="110" height="10"/>
          <p className="text-md text-gray-700">+33750006427</p>
        </Link>
        </div>
      </div>

      {/* Menu Mobile */}
      <div className={`${mobileMenuOpen ? 'block' : 'hidden'} md:hidden`}>
        <nav className="flex flex-col">
          <Link to="/" className="flex items-center p-2" onClick={handleCloseMobileMenu}>
          <img src={Home} alt="Menu" className="h-6 mr-2" width="26" height="26"/> 
            Accueil
          </Link>

          {/* Dropdown pour les produits */}
          <div>
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center p-2 text-customBlack bg-transparent border-none"
              
            >
              <img src={produits} alt="Produits" className="h-6 mr-2" width="26" height="26"/>
              Produits
            </button>
            {dropdownOpen && (
              <div className="flex flex-col text-left" ref={dropdownRefMobile}>
                <Link to="/veranda" className="p-2" onClick={handleCloseMobileMenu}>Veranda</Link>
                <Link to="/auvent" className="p-2" onClick={handleCloseMobileMenu}>Auvent</Link>
                <Link to="/protection-solaire" className="p-2" onClick={handleCloseMobileMenu}>Protection solaire</Link>
                <Link to="/parois-coulissante" className="p-2" onClick={handleCloseMobileMenu}>Parois coulissante</Link>
                <Link to="/portes-coulissante" className="p-2" onClick={handleCloseMobileMenu}>Portes coulissante</Link>
                <Link to="/catalogue" className="p-2" onClick={handleCloseMobileMenu}>Catalogue</Link>
                <Link to="/details-technique" className="p-2" onClick={handleCloseMobileMenu}>Détails technique</Link>
              </div>
            )}
          </div>

          <Link to="/faq" className="flex items-center p-2" onClick={handleCloseMobileMenu}>
          <img src={faq} alt="FAQ" className="h-6 mr-2" width="26" height="26"/>
            FAQ
          </Link>

          <Link to="/contact" className="flex items-center p-2" onClick={handleCloseMobileMenu}>
          <img src={contact} alt="Contact" className="h-6 mr-2" width="26" height="26"/>
            Nous contacter
          </Link>

          <Link to="/devis" className="flex items-center p-2" onClick={handleCloseMobileMenu}>
          <img src={devis} alt="Devis" className="h-6 mr-2" width="26" height="26"/>
            Faire un devis
          </Link>
          <Link to="/articles" className="flex items-center p-2" onClick={handleCloseMobileMenu}>
          <img src={articles} alt="articles" className="h-6 mr-2" width="26" height="26"/>
            Articles
          </Link>
        </nav>
      </div>
      <div className="hidden md:flex container mx-auto flex items-center justify-evenly px-4">
  <Link to="/" className="flex items-center">
    <img src={logo} className="h-20" alt="logo Patek Verandas veranda a lille" width="140" height="80"/>
  </Link>
  <nav className="flex items-center space-x-8">
    <Link to="/" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={Home} alt="Accueil" className="h-6 mr-2" width="26" height="26"/> Accueil
    </Link>
    <div className="relative inline-block">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="relative top-1 text-customBlack bg-transparent border-none inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80 focus:outline-none"
      >
        <img src={produits} alt="Produits" className="h-6 mr-2" width="26" height="26"/>
        Produits
      </button>
      {dropdownOpen && (
        <div className="absolute left-0 mt-2 py-2 w-56 bg-white rounded-md shadow-lg divide-y divide-gray-300" ref={dropdownRefDesktop}>
          <Link to="/veranda" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Veranda</Link>
          <Link to="/auvent" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Auvent</Link>
          <Link to="/protection-solaire" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Protection Solaire</Link>
          <Link to="/parois-coulissante" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Parois Coulissante en Verre</Link>
          <Link to="/portes-coulissante" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Portes Coulissante en Verre</Link>
          <Link to="/catalogue" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Catalogue</Link>
          <Link to="/details-technique" className="block px-4 py-2 text-sm hover:bg-gray-100" onClick={() => setDropdownOpen(false)}>Détails technique</Link>
        </div>
      )}
    </div>
    <Link to="/faq" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={faq} alt="FAQ" className="h-6 mr-2" width="26" height="26"/> FAQ
    </Link>
    <Link to="/contact" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={contact} alt="Contacter" className="h-6 mr-2" width="26" height="26"/> Nous contacter
    </Link>
    <Link to="/devis" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={devis} alt="Devis" className="h-6 mr-2" width="26" height="26"/> Faire un devis
    </Link>
    <Link to="/articles" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={articles} alt="articles" className="h-6 mr-2" width="26" height="26"/> Articles
    </Link>
    <Link to="tel:+33750006427" className="inline-flex items-center transition duration-300 ease-in-out hover:text-opacity-80">
      <img src={phone} alt="telephone" className="h-6 mr-1" width="26" height="26"/>
      <p className="text-lg text-gray-700">+33750006427</p>
    </Link>
  </nav>
</div>

      </div>
    );
};

export default Navigation;
