import React, { useEffect, useState } from "react";
import Hero from '../components/Hero';
import Features from '../components/Features';
import heroImageLarge from '../assets/images/hero-background.webp';
import heroImagePhone from '../assets/images/hero-background_phone.webp';
import { Helmet } from "react-helmet";

const Home = () => {
  const [image, setImage] = useState(heroImageLarge);

  useEffect(() => {
    const updateImage = () => {
      const width = window.innerWidth;
      if (width >= 768) {
        setImage(heroImageLarge);
      } else {
        setImage(heroImagePhone);
      }
    };

    const preloadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    };

    updateImage();
    window.addEventListener('resize', updateImage);

    // Preload images
    preloadImage(heroImageLarge);
    preloadImage(heroImagePhone);

    return () => {
      window.removeEventListener('resize', updateImage);
    };
  }, []);

  return (
    <main>
      <Helmet>
        <title>Patek Verandas - Vérandas a Lille de Qualité à Prix Bas à Lille et Nord - Livraison Rapide de véranda a Lille et Belgique</title>
        <meta name="description" content="Découvrez nos vérandas a Lille signée Patek Verandas de haute qualité à des prix bas et compétitifs. Service rapide et fiable de veranda a Lille, dans le Nord, ainsi qu'en Belgique. Conçues pour la durabilité et l'esthétique par Patek Verandas - Patek Verandas." />
      </Helmet>
      <Hero 
        image={image} 
        btn={true} 
        height="75"
      />
      <Features />
    </main>
  );
};

export default Home;
