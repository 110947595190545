import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Hero from '../components/Hero';
import ImageDevi from './../assets/images/devis.webp'
import Veranda from './../assets/images/Veranda/Veranda3.webp'
import Carport from './../assets/images/Carport.webp'
import Pergola from './../assets/images/Pergola.webp'
import Tpoly from './../assets/images/devis/Tpoly.webp'
import Tverre from './../assets/images/devis/Tverre.webp'
import Vclair from './../assets/images/devis/Vclair.webp'
import Vopale from './../assets/images/devis/Vopale.webp'
import Pclair from './../assets/images/devis/Pclair.webp'
import Popale from './../assets/images/devis/Popale.webp'
import Vteintecoulissant from './../assets/images/devis/Vteintecoulissant.webp'
import Vclaircoulissant from './../assets/images/devis/Vclaircoulissant.webp'
import Bvitreecoullisante from './../assets/images/devis/Bvitreecoullisante.webp'
import Vcoulissant from './../assets/images/devis/Vcoulissant.webp'
import Ppolycarbonate from './../assets/images/devis/Ppolycarbonate.webp'
import Psandwich from './../assets/images/devis/Psandwich.webp'
import Baie from './../assets/images/devis/Baie.webp'
import Fenetres from './../assets/images/devis/Fenetres.webp'
import Paluminuim from './../assets/images/devis/Paluminuim.webp'
import Vcoulisantclair from './../assets/images/devis/Vcoulisantclair.webp'
import Vcoulisantteinte from './../assets/images/devis/Vcoulisantteinte.webp'
import Ppolyclair from './../assets/images/devis/Ppolyclair.webp'
import Ppolyopale from './../assets/images/devis/Ppolyopale.webp'
import PanneauPlein from './../assets/images/devis/PanneauPlein.webp'
import PanneauSpie from './../assets/images/devis/PanneauSpie.webp'
import Bcoulissante from './../assets/images/devis/Bcoulissante.webp'
import Bfixe from './../assets/images/devis/Bfixe.webp'
import Rien from './../assets/images/devis/Rien.webp'
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useQuote } from './../services/QuoteContext';

const Devis = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [choices, setChoices] = useState({
    product: '',
    roof: '',
    roofType: '',
    facade: '',
    lateralWallGauche: '',
    lateralWallGaucheType: '',
    lateralWallDroite: '',
    lateralWallDroiteType: ''
  });
  const [confirmed, setConfirmed] = useState(false);

  const navigate = useNavigate();
  const { submitQuote } = useQuote();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    if (modalStep !== 9) { // Changer à 9 pour inclure les nouvelles étapes
      setConfirmed(false);
    }
    setModalStep(1);
    setChoices({});
  };

  const handleChoice = (field, value) => {
    setChoices(prevChoices => ({ ...prevChoices, [field]: value }));
    console.log(choices);
    console.log(modalStep);
  
    const nextStepMap = {
      product: 2,
      roof: 3,
      roofType: 4,
      facade: 5,
      lateralWallGauche: 6,
      lateralWallGaucheType: 7,
      lateralWallDroite: 8,
      lateralWallDroiteType: 9
    };
  
    if (choices.product !== 'Véranda' && modalStep >= 3) {
      setModalStep(9); // Skip facade step if product is not Véranda
    } else if (field === 'lateralWallGauche' && (value === 'Rien' || value === 'Fenetres coulissantes' || value === 'Panneau en aluminium')) {
      setModalStep(7); // Skip to lateralWallDroite choice if certain values are chosen for lateralWallGauche
    } else if (field === 'lateralWallDroite' && (value === 'Rien' || value === 'Fenetres coulissantes' || value === 'Panneau en aluminium')) {
      setModalStep(9); // Skip to the end if certain values are chosen for lateralWallDroite
    } else {
      setModalStep(nextStepMap[field] || (modalStep + 1));
    }
  };
  
  const handlePreviousStep = () => {
    const previousStepMap = {
      9: choices.lateralWallDroite ? 7 : 6,
      8: 6,
      7: 5,
      6: 4,
      5: 4,
      4: choices.roofType ? 3 : 2,
      3: 2,
      2: 1
    };
  
    setModalStep(previousStepMap[modalStep] || (modalStep - 1));
  };

  const handleConfirm = () => {
    setConfirmed(true);
    setTimeout(() => {
      closeModal();
    }, 1000); // Fermer la modale après 1 seconde
  };

  const formik = useFormik({
    initialValues: {
      dimensions: '',
      color: '',
      situationPhoto: null,
      firstname: '',
      lastname: '', 
      email: '',
      phone: '',
      address: '',
      additionalAddress: '',
      city: '',
      postcode: '',
      contactConsent: false,
      productChoice: '',
      roofChoice: '',
      roofTypeChoice: '',
      facadeChoice: '',
      lateralWallGaucheChoice: '',
      lateralWallGaucheTypeChoice: '',
      lateralWallDroiteChoice: '',
      lateralWallDroiteTypeChoice: '',
    },
    validationSchema: Yup.object({
      dimensions: Yup.string().matches(/^\d+x\d+$/, 'Veuillez entrer des dimensions valides (ex: 1000x2000 pour largeur x hauteur en mm).').required('Les dimensions sont requises'),
      color: Yup.string().oneOf(['blanc_creme', 'noir', 'gris anthracite', 'a_determiner'], 'Couleur invalide').required('La couleur est requise'),
      situationPhoto: Yup.mixed().nullable().notRequired(),
      firstname: Yup.string().matches(/^[a-zA-ZÀ-ÿ-'\s]+$/, 'Prénom invalide').required('Le prénom est requis'),
      lastname: Yup.string().matches(/^[a-zA-ZÀ-ÿ-'\s]+$/, 'Nom de famille invalide').required('Le nom de famille est requis'),
      email: Yup.string().email('Adresse email invalide').required('L\'email est requis'),
      phone: Yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Numéro de téléphone invalide').required('Le numéro de téléphone est requis'),
      address: Yup.string().required('L\'adresse est requise'),
      additionalAddress: Yup.string().notRequired(),
      city: Yup.string().matches(/^[a-zA-ZÀ-ÿ-'\s]+$/, 'Nom de ville invalide').required('La ville est requise'),
      postcode: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Code postal invalide').required('Le code postal est requis'),
      contactConsent: Yup.boolean().oneOf([true], 'Vous devez accepter d\'être contacté pour continuer'),
    }),
    onSubmit: (values) => {
      const submissionValues = {
        ...values,
        Produit: choices.productChoice,
        Toit: choices.roof,
        TypeDeToit: choices.roofType,
        Facade: choices.facade,
        ParoisLateralesGauche: choices.lateralWallGauche,
        TypeParoisLateralesGauche: choices.lateralWallGaucheType,
        ParoisLateralesDroite: choices.lateralWallDroite,
        TypeParoisLateralesDroite: choices.lateralWallDroiteType,
      };

      fetch('https://formspree.io/f/xvoegokw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionValues),
        enctype: "multipart/form-data"
      })
        .then(response => {
          if (response.ok) {
            formik.resetForm();
            submitQuote(); // Mettre à jour l'état du devis
            navigate('/confirmation'); // Rediriger vers la page de confirmation
          } else {
            alert('Une erreur est survenue lors de l\'envoi de votre demande.');
          }
        })
        .catch(error => {
          console.error('Erreur lors de l\'envoi du formulaire :', error);
        });
    },
  });

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#2A4DAB', // Ajout de la couleur du texte
  };
  
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '800px',
      height: '90%',
      maxHeight: '90vh', // Assurer que la hauteur maximale ne dépasse pas la taille de l'écran
      margin: 'auto',
      overflow: 'hidden',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start', // Changer la justification pour le haut
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };
  
  const imageStyle = {
    width: '150px', // Taille pour les petits écrans
    height: '150px',
    objectFit: 'cover',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const imageStyleLarge = {
    width: '250px', // Taille pour les grands écrans
    height: '250px',
    objectFit: 'cover',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const buttonStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px',
    margin: '10px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
  };
  
  const textStyle = {
    fontSize: '16px',
    color: '#2A4DAB', // Couleur du texte
    marginTop: '10px',
  };
  
  const contentWrapperStyle = {
    overflowY: 'auto',
    maxHeight: 'calc(100% - 80px)', // Ajuster la hauteur disponible pour les images en fonction du titre et des boutons
    width: '100%',
  };
  

  return (
<main>
    <Helmet>
    <title>Devis Rapide pour Vérandas et Auvents à Lille - Patek Verandas</title>
    <meta name="description" content="Demandez un devis rapide et à prix bas pour votre véranda ou auvent avec Patek Verandas. Service disponible à Lille, dans le Nord, en Belgique et en France." />
    </Helmet>
    <Hero image={ImageDevi} titre="Faire un devis" height="55"/>
<div className="container mx-auto px-4 py-8 w-[60%] relative bottom-24 max-md:w-full">
    <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded p-12 pb-8 mb-4">
    <div className="mb-6">
    <button
        type="button"
        className={`w-full ${confirmed ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-500 ease-in-out`}
        onClick={confirmed ? null : openModal}
        disabled={confirmed}
      >
        {confirmed ? (
          <div className="flex items-center justify-center">
            <span className="w-6 h-6 flex items-center justify-center bg-white rounded-full">
              <svg
                className="w-4 h-4 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </span>
          </div>
        ) : (
          'Choix du produit'
        )}
      </button>
</div>

<Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Choix du produit"
      >
        <h2 style={titleStyle}>Choix du produit</h2>
        <div style={contentWrapperStyle}>
          {modalStep === 1 && (
            <div className="flex flex-wrap justify-center items-center">
              <button onClick={() => handleChoice('product', 'Véranda')} style={buttonStyle}>
                <img src={Veranda} alt="Véranda" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                <p style={textStyle}>Véranda</p>
              </button>
              <button onClick={() => handleChoice('product', 'Carports')} style={buttonStyle}>
                <img src={Carport} alt="Carports" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                <p style={textStyle}>Carports</p>
              </button>
              <button onClick={() => handleChoice('product', 'Pergola')} style={buttonStyle}>
                <img src={Pergola} alt="Pergola" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                <p style={textStyle}>Pergola</p>
              </button>
            </div>
          )}
          {modalStep === 2 && (
            <div>
              <h2 style={titleStyle}>Choix de la toiture</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('roof', 'Polycarbonate')} style={buttonStyle}>
                  <img src={Tpoly} alt="Polycarbonate" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Polycarbonate</p>
                </button>
                <button onClick={() => handleChoice('roof', 'Verre')} style={buttonStyle}>
                  <img src={Tverre} alt="Verre" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 3 && choices.roof === 'Verre' && (
            <div>
              <h2 style={titleStyle}>Type de Verre</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('roofType', 'Verre clair')} style={buttonStyle}>
                  <img src={Vclair} alt="Verre clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre clair</p>
                </button>
                <button onClick={() => handleChoice('roofType', 'Verre feuilleté opale')} style={buttonStyle}>
                  <img src={Vopale} alt="Verre feuilleté opale" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre feuilleté opale</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 3 && choices.roof === 'Polycarbonate' && (
            <div>
              <h2 style={titleStyle}>Type de Polycarbonate</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('roofType', 'Clair')} style={buttonStyle}>
                  <img src={Pclair} alt="Clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Clair</p>
                </button>
                <button onClick={() => handleChoice('roofType', 'Opale')} style={buttonStyle}>
                  <img src={Popale} alt="Opale" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Opale</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 4 &&  (
            <div>
              <h2 style={titleStyle}>Choix de la façade</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('facade', 'Verre teinté coulissant')} style={buttonStyle}>
                  <img src={Vteintecoulissant} alt="Verre teinté coulissant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre teinté coulissant</p>
                </button>
                <button onClick={() => handleChoice('facade', 'Verre clair coulissant')} style={buttonStyle}>
                  <img src={Vclaircoulissant} alt="Verre clair coulissant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre clair coulissant</p>
                </button>
                <button onClick={() => handleChoice('facade', 'Baie vitrée coulissante')} style={buttonStyle}>
                  <img src={Bvitreecoullisante} alt="Baie vitrée coulissante" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Baie vitrée coulissante</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 5 && (
            <div>
              <h2 style={titleStyle}>Choix des parois latérales gauche</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallGauche', 'Verre coulissant')} style={buttonStyle}>
                  <img src={Vcoulissant} alt="Verre coulissant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre coulissant</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Panneau en polycarbonate')} style={buttonStyle}>
                  <img src={Ppolycarbonate} alt="Panneau en polycarbonate" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau en polycarbonate</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Panneau sandwich isolant')} style={buttonStyle}>
                  <img src={Psandwich} alt="Panneau sandwich isolant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau sandwich isolant</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Baie')} style={buttonStyle}>
                  <img src={Baie} alt="Baie" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Baie</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Fenêtres coulissantes')} style={buttonStyle}>
                  <img src={Fenetres} alt="Fenêtres coulissantes" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Fenêtres coulissantes</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Panneau en aluminium')} style={buttonStyle}>
                  <img src={Paluminuim} alt="Panneau en aluminium" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau en aluminium</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGauche', 'Rien')} style={buttonStyle}>
                  <img src={Rien} alt="Rien" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Rien</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 6 && choices.lateralWallGauche === 'Verre coulissant' && (
            <div>
              <h2 style={titleStyle}>Type de Verre Coulissant Gauche</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Clair')} style={buttonStyle}>
                  <img src={Vcoulisantclair} alt="Clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Clair</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Teinté')} style={buttonStyle}>
                  <img src={Vcoulisantteinte} alt="Teinté" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Teinté</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 6 && choices.lateralWallGauche === 'Panneau en polycarbonate' && (
            <div>
              <h2 style={titleStyle}>Type de Panneau en Polycarbonate Gauche</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Clair')} style={buttonStyle}>
                  <img src={Ppolyclair} alt="Clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Clair</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Opale')} style={buttonStyle}>
                  <img src={Ppolyopale} alt="Opale" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Opale</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 6 && choices.lateralWallGauche === 'Panneau sandwich isolant' && (
            <div>
              <h2 style={titleStyle}>Type de Panneau Sandwich Isolant Gauche</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Panneau plein')} style={buttonStyle}>
                  <img src={PanneauPlein} alt="Panneau plein" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau plein</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Panneau avec Spie')} style={buttonStyle}>
                  <img src={PanneauSpie} alt="Panneau avec Spie" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau avec Spie</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 6 && choices.lateralWallGauche === 'Baie' && (
            <div>
              <h2 style={titleStyle}>Type de Baie Gauche</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Fixe')} style={buttonStyle}>
                  <img src={Bfixe} alt="Fixe" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Fixe</p>
                </button>
                <button onClick={() => handleChoice('lateralWallGaucheType', 'Coulissante')} style={buttonStyle}>
                  <img src={Bcoulissante} alt="Coulissante" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Coulissante</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 7 && (
            <div>
              <h2 style={titleStyle}>Choix des parois latérales droite</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallDroite', 'Verre coulissant')} style={buttonStyle}>
                  <img src={Vcoulissant} alt="Verre coulissant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Verre coulissant</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Panneau en polycarbonate')} style={buttonStyle}>
                  <img src={Ppolycarbonate} alt="Panneau en polycarbonate" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau en polycarbonate</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Panneau sandwich isolant')} style={buttonStyle}>
                  <img src={Psandwich} alt="Panneau sandwich isolant" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau sandwich isolant</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Baie')} style={buttonStyle}>
                  <img src={Baie} alt="Baie" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Baie</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Fenêtres coulissantes')} style={buttonStyle}>
                  <img src={Fenetres} alt="Fenêtres coulissantes" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Fenêtres coulissantes</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Panneau en aluminium')} style={buttonStyle}>
                  <img src={Paluminuim} alt="Panneau en aluminium" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau en aluminium</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroite', 'Rien')} style={buttonStyle}>
                  <img src={Rien} alt="Rien" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Rien</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 8 && choices.lateralWallDroite === 'Verre coulissant' && (
            <div>
              <h2 style={titleStyle}>Type de Verre Coulissant Droite</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Clair')} style={buttonStyle}>
                  <img src={Vcoulisantclair} alt="Clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Clair</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Teinté')} style={buttonStyle}>
                  <img src={Vcoulisantteinte} alt="Teinté" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Teinté</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 8 && choices.lateralWallDroite === 'Panneau en polycarbonate' && (
            <div>
              <h2 style={titleStyle}>Type de Panneau en Polycarbonate Droite</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Clair')} style={buttonStyle}>
                  <img src={Ppolyclair} alt="Clair" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Clair</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Opale')} style={buttonStyle}>
                  <img src={Ppolyopale} alt="Opale" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Opale</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 8 && choices.lateralWallDroite === 'Panneau sandwich isolant' && (
            <div>
              <h2 style={titleStyle}>Type de Panneau Sandwich Isolant Droite</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Panneau plein')} style={buttonStyle}>
                  <img src={PanneauPlein} alt="Panneau plein" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau plein</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Panneau avec Spie')} style={buttonStyle}>
                  <img src={PanneauSpie} alt="Panneau avec Spie" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Panneau avec Spie</p>
                </button>
              </div>
            </div>
          )}
          {modalStep === 8 && choices.lateralWallDroite === 'Baie' && (
            <div>
              <h2 style={titleStyle}>Type de Baie Droite</h2>
              <div className="flex flex-wrap justify-center items-center">
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Fixe')} style={buttonStyle}>
                  <img src={Bfixe} alt="Fixe" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Fixe</p>
                </button>
                <button onClick={() => handleChoice('lateralWallDroiteType', 'Coulissante')} style={buttonStyle}>
                  <img src={Bcoulissante} alt="Coulissante" style={window.innerWidth >= 1024 ? imageStyleLarge : imageStyle} width="1200" height="800"/>
                  <p style={textStyle}>Coulissante</p>
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-evenly w-full mt-4 items-center">
          {modalStep > 1 && (
            <button onClick={handlePreviousStep} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" aria-label='retour'>
              Retour
            </button>
          )}
          {modalStep === 9 && (
            <button
              onClick={handleConfirm}
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' aria-label='confirmer'
            >
           Confirmer
            </button>
          )}
        </div>
      </Modal>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="dimensions"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dimensions}
            placeholder="Entrez les dimensions en mm"
            />
            {formik.touched.dimensions && formik.errors.dimensions ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.dimensions}</p>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <select
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="color"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.color}
            >
                <option value="">Choisissez une couleur</option>
                <option value="blanc">Blanc</option>
                <option value="blanc_creme">Blanc Crème</option>
                <option value="noir">Noir</option>
                <option value="anthracite">Anthracite</option>
                <option value="a_determiner">À déterminer</option>
            </select>
            {formik.touched.color && formik.errors.color ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.color}</p>
            ) : null}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="situationPhoto">
                Photo de la situation (optionnel)
                </label>
                <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="situationPhoto"
                type="file"
                name="situationPhoto"
                accept="image/webp, image/jpeg"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
                {formik.touched.situationPhoto && formik.errors.situationPhoto ? (
                    <p className="text-red-500 text-xs italic">{formik.errors.situationPhoto}</p>
                ) : null}
            </div>
        </div>
            
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="firstname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstname}
            placeholder="Prénom"
            />
            {formik.touched.firstname && formik.errors.firstname ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.firstname}</p>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            name="lastname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastname}
            placeholder="Nom de famille"
            />
            {formik.touched.lastname && formik.errors.lastname ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.lastname}</p>
            ) : null}
          </div>
        </div>
            
            
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="email"
            type="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="phone"
            type="tel"
            placeholder="Téléphone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.phone}</p>
            ) : null}
          </div>
        </div>
            
   
        <div className='mb-6'>
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="address"
            type="text"
            placeholder="Adresse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.address}</p>
            ) : null}
        </div>
        
        <div className='mb-6'>
            <input
            className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="additionalAddress"
            type="text"
            placeholder="Complément d'adresse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.additionalAddress}
            />
            {formik.touched.additionalAddress && formik.errors.additionalAddress ? (
                 <p className="text-red-500 text-xs italic">{formik.errors.additionalAddress}</p>
            ) : null}
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <input
                className="focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="city"
                type="text"
                placeholder="Ville"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city ? (
                     <p className="text-red-500 text-xs italic">{formik.errors.city}</p>
                ) : null}
            </div>
            <div className="w-full md:w-1/2 px-3">
                <input
                className="mb-6 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                name="postcode"
                type="text"
                placeholder="Code Postal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postcode}
                />
                {formik.touched.postcode && formik.errors.postcode ? (
                    <p className="text-red-500 text-xs italic">{formik.errors.postcode}</p>
                ) : null}
            </div>
        </div>
        
        <div className='mb-6'>
            <div className="flex items-center mt-4">
                <label className="inline-flex items-center">
                    <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                    name="contactConsent"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.contactConsent}
                    />
                    <span className="ml-2 text-gray-700">
                        J'accepte d'être contacté par Patek Verandas concernant cette demande de devis.
                    </span>
                </label>
            </div>
            {formik.touched.contactConsent && formik.errors.contactConsent ? (
                <p className='text-red-600 text-sm mt-1'>{formik.errors.contactConsent}</p>
            ) : null}
        </div>

  
        <div className="flex items-center justify-between max-md:justify-center">
            <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit">
            Envoyer la demande
        </button>
        </div>
    </form>
</div>
</main>
  );
};

export default Devis;
