import React from 'react';

const WorkCard = ({ index, image, title, description }) => {
    // Sépare le texte en paragraphes en divisant la description par des points.
    const paragraphs = description.split('. ').map((sentence, index, array) => {
        // Ajoutez un point à la fin de chaque phrase, sauf si c'est la dernière phrase.
        return index === array.length - 1 ? sentence : sentence + '.';
      });
    
    return (
      <div key={index} className="shadow-lg rounded-lg overflow-hidden transition-transform duration-500 hover:scale-105">
        <img src={image} alt={title} className="w-full h-64 object-cover transition duration-500 hover:opacity-90" width="1200" height="800"/>
        <div className="p-6 bg-white bg-opacity-90">
          <h4 className="text-2xl font-semibold mb-4 text-gradient">{title}</h4>
          {paragraphs.map((paragraph, idx) => (
            <p key={idx} className="text-gray-600 text-left mb-4 last:mb-0">
              {paragraph.trim()}
            </p>
          ))}
        </div>
      </div>
    );
  };
  

export default WorkCard;
