import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCard = ({ id, title, image }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-lg">
      <Link to={`/articles/${id}`}>
        <img src={image} alt={title} className="w-full h-48 object-cover" 
        width="1200"
        height="800"/>
        <div className="p-4">
          <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
        </div>
      </Link>
    </div>
  );
};

export default ArticleCard;
