import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './App.css';
import App from './App';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const MainApp = () => {
  useEffect(() => {
    window.addEventListener('load', () => {
      ReactGA.initialize('G-01CR0PZR62');
      history.listen(location => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
      });

      // Charger Google Tag Manager de manière asynchrone après le chargement de la page
      const gtmScript = document.createElement('script');
      gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-01CR0PZR62';
      gtmScript.async = true;
      document.body.appendChild(gtmScript);
    });
  }, []);

  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);
