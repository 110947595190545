import React, { createContext, useState, useContext } from 'react';

const QuoteContext = createContext();

export const useQuote = () => useContext(QuoteContext);

export const QuoteProvider = ({ children }) => {
  const [hasSubmittedQuote, setHasSubmittedQuote] = useState(false);

  const value = {
    hasSubmittedQuote,
    submitQuote: () => setHasSubmittedQuote(true),
  };

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
};
