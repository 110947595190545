import React from 'react';
import Hero from './Hero';
import { Parallax } from 'react-parallax';
import Carousel from './Carousel';

const VerandaSection = ({ title, paragraphs, imageUrl}) => (
  <div className='w-full pt-8 pb-16 bg-blue-100 '>
    <div className="container mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between py-12">
        <div className="md:w-1/3 m-auto max-md:p-8">
          <Parallax strength={300}>
            <h2 className="text-3xl font-semibold text-left text-customBlue mb-8">
              {title}
            </h2>
            {paragraphs.map((paragraph, index) => (
              <p key={index} className="text-lg text-left text-gray-800 leading-relaxed mb-4">
                {paragraph}
              </p>
              ))}
          </Parallax>
        </div>
        <div className="md:w-1/2 flex justify-center mt-8 md:mt-0">
          <img src={imageUrl} alt="Veranda" className="rounded-lg shadow-lg max-h-[600px]" width="1200" height="800"/>
        </div>
      </div>
    </div>
  </div>
);

const PageContent = ({ 
  heroTitle, 
  heroImage, 
  preCarouselSection, 
  postCarouselSection, 
  carouselImages, 
}) => {
  return (
    <div>
      <Hero titre={heroTitle} height="65" image={heroImage}/>
      {preCarouselSection && <VerandaSection {...preCarouselSection} />}
      <div className='bg-amber-50 pt-8 pb-8'>
        <Carousel images={carouselImages}/>
      </div>
      {postCarouselSection && <VerandaSection {...postCarouselSection} />}
      <div className="veranda-cta text-center bg-blue-100">
        <button className="button-gradient text-white mb-12" onClick={() => window.location.href = '/devis'}>
          Faire un devis
        </button>
      </div>
    </div>
  );
};

export default PageContent;
