import React from 'react';
import { Link } from 'react-router-dom';
import whatsapp from '../assets/styles/whatsapp.svg';
import facebook from '../assets/styles/facebook.svg';
import instagram from '../assets/styles/instagram.svg';
import snapchat from '../assets/styles/snapchat.svg';
import phone from '../assets/styles/phone-icon-white.svg';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
        {/* Contact */}
        <div>
          <h5 className="uppercase mb-6 font-bold">Contact</h5>
          <p>38 boulevard carnot, Lille, France, 59800</p>
          <p><a className='text-white' href="mailto:Service-commercial@patekverandas.fr">Service-commercial@patekverandas.fr</a></p>
          <p><a className='text-white' href="mailto:contact@patekverandas.com">contact@patekverandas.com</a></p>
          <div className="mt-4 text-white">
            <p>Service Commercial</p>
            <div className="text-white flex flex-wrap items-center footerphone">
              <a className='text-white flex flex-wrap items-center' href="tel:+33782652280">+33 7 82 65 22 80 <img src={phone} alt="telephone" className="h-6 mx-2 phone-icon" width="24" height="24"/></a>
              <a href="https://wa.me/33782652280" target="_blank"><img src={whatsapp} alt="whatsapp" className="h-6 mx-2" width="24" height="24"/></a>
            </div>
          </div>
          <div className="text-white">
            <p>Service Technique</p>
            <div className="flex flex-wrap items-center footerphone">
              <a className='text-white flex flex-wrap items-center' href="tel:+33750006427">+33 7 50 00 64 27  <img src={phone} alt="telephone" className="h-6 mx-2" width="24" height="24"/></a>
              <a href="https://wa.me/33750006427" target="_blank"><img src={whatsapp} alt="whatsapp" className="h-6 mx-2" width="24" height="24"/></a>
            </div>
          </div>
          <div className="">
            <p>Bureau</p>
            <a className='text-white flex flex-wrap items-center footerphone' href="tel:+33980523008">+33 9 80 52 30 08 <img src={phone} alt="telephone" className="h-6 mx-2" width="24" height="24"/></a>
          </div>
        </div>

        {/* Liens rapides */}
        <div>
          <h5 className="uppercase mb-6 font-bold">Liens Rapides</h5>
          <ul className="list-none">
            <li className="mb-4"><Link to="/aboutus" className='text-white'>A propos</Link></li>
            <li className="mb-4"><Link to="/contact" className='text-white'>Contact</Link></li>
            <li className="mb-4"><Link to="/veranda" className='text-white'>Nos Verandas</Link></li>
            <li className="mb-4"><Link to="/faq" className='text-white'>FAQ</Link></li>
          </ul>
        </div>

        {/* Réseaux sociaux */}
        <div>
          <h5 className="uppercase mb-6 font-bold">Réseaux Sociaux</h5>
          <ul className="list-none">
            <li className="mb-4"><a className='text-white flex max-md:justify-center' href="https://www.facebook.com/profile.php?id=61557733479722" target="_blank"><img src={facebook} alt="facebook" className="h-6 mr-2" width="24" height="24"/>Patek Verandas</a></li>
            <li className="mb-4"><a className='text-white flex max-md:justify-center' href="https://www.instagram.com/patekverandas/" target="_blank"><img src={instagram} alt="instagram" className="h-6 mr-2" width="24" height="24"/>Patekverandas</a></li>
            <li className="mb-4"><a className='text-white flex max-md:justify-center' href="https://www.snapchat.com/add/patekverandas" target="_blank" rel="noopener noreferrer"><img src={snapchat} alt="snapchat" className="h-6 mr-2" width="24" height="24"/>Patekverandas</a></li>
          </ul>
        </div>

        {/* Légal */}
        <div>
          <h5 className="uppercase mb-6 font-bold">Légal</h5>
          <p><Link to="/privacy-policy" className='text-white'>Politique de confidentialité</Link></p>
          <p><Link to="/terms-conditions" className='text-white'>Termes et conditions</Link></p>
        </div>

        {/* Copyright */}
        <div className="col-span-1 md:col-span-4 text-center mt-8 md:mt-0">
          <p>© {new Date().getFullYear()} PATEK VERANDAS. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
