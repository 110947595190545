import React from 'react';
import Hero from '../components/Hero';
import Politique from '../assets/images/politique.webp'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <main>
    <Hero image={Politique} titre="politique de confidentialité" height="55"/>
    <div className="container mx-auto my-8 p-8 bg-white shadow-lg rounded-lg">

  <section className="mb-6">
    <h2 className="text-2xl font-semibold text-gray-700">Données collectées</h2>
    <p className="text-gray-600 mt-2">Aucune donnée personnelle n'est collectée par notre site web.</p>
  </section>

  <section className="mb-6">
    <h2 className="text-2xl font-semibold text-gray-700">Communication avec l'entreprise</h2>
    <p className="text-gray-600 mt-2">Toute communication avec notre entreprise, y compris les demandes de service pour l'installation de vérandas, se fait directement et personnellement via téléphone, email ou en personne.</p>
  </section>

  <section className="mb-6">
    <h2 className="text-2xl font-semibold text-gray-700">Cookies et Suivi</h2>
    <p className="text-gray-600 mt-2">Nous n'utilisons aucun cookie pour suivre les comportements des utilisateurs. Notre site web est purement informatif et vise à fournir des informations sur nos services sans suivre les visiteurs.</p>
  </section>

  <section className="mb-6">
    <h2 className="text-2xl font-semibold text-gray-700">Sécurité</h2>
    <p className="text-gray-600 mt-2">La sécurité de vos informations est importante pour nous. Comme notre site web ne collecte pas de données personnelles, vous pouvez être assuré que vos informations personnelles ne sont pas à risque lors de la visite de notre site web.</p>
  </section>

  <section className="mb-6">
    <p className="text-gray-600 mt-2">Cette Politique de confidentialité peut être mise à jour de temps en temps. Nous vous conseillons de consulter cette page périodiquement pour tout changement.</p>
  </section>

  <div className="text-center mt-8">
    <Link to="/contact" className="text-blue-600 hover:underline">Contactez-nous</Link>
  </div>
</div>

    </main>
  );
};

export default PrivacyPolicy;
