import React, { useState, useRef } from 'react';

const Carousel = ({ images }) => {
  const trackRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [parallaxOffset, setParallaxOffset] = useState(0); // Stocker l'offset de parallaxe
  const [dragging, setDragging] = useState(false);

  
  const handleTouchStart = (e) => {
    const touchX = e.touches[0].clientX;
    setStartX(touchX);
    setIsDragging(true);
    setDragging(false); // Réinitialiser l'état de glissement
  };
  
  const handleTouchMove = (e) => {
    if (isDragging) {
      setDragging(true);
      const touchX = e.touches[0].clientX;
      const movementX = touchX - startX;
      const newOffset = offset + movementX;
      setOffset(newOffset);
  
      trackRef.current.style.transform = `translateX(${newOffset}px)`;
      const newParallaxOffset = movementX + parallaxOffset;
      updateImagePositions(newParallaxOffset);
      setParallaxOffset(newParallaxOffset);
  
      setStartX(touchX);
    }
  };
  
  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const updateImagePositions = (movementX) => {
    Array.from(trackRef.current.children).forEach((img) => {
      const parallaxFactor = 37;
      const imageOffset = movementX / parallaxFactor;
      img.style.objectPosition = `${50 + imageOffset}% center`;
    });
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
    setDragging(false); // Réinitialiser l'état de glissement
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
        setDragging(true);
      const currentX = e.clientX;
      const movementX = currentX - startX;
      const newOffset = offset + movementX;
      setOffset(newOffset);

      trackRef.current.style.transform = `translateX(${newOffset}px)`;
      const newParallaxOffset = movementX + parallaxOffset;
      updateImagePositions(newParallaxOffset);
      setParallaxOffset(newParallaxOffset);

      setStartX(currentX);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    // Conserver l'offset de parallaxe actuel
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (src) => {
    if (!dragging) {
        setSelectedImage(src);
      }
  };

  const closeImage = () => {
    setSelectedImage(null);
  };


  return (
    <div className="carousel-wrapper overflow-hidden">
    <div
  id="image-track"
  ref={trackRef}
  className="carousel-track" // Ajout de classes Tailwind pour overflow et positionnement
  onMouseDown={handleMouseDown}
  onMouseMove={handleMouseMove}
  onMouseUp={handleMouseUp}
  onMouseLeave={handleMouseUp}
  onTouchStart={handleTouchStart}
  onTouchMove={handleTouchMove}
  onTouchEnd={handleTouchEnd}
  style={{ transform: `translateX(${offset}px)` }}
>
  {images.map((src, index) => (
    <img key={index} 
    src={src} className="image w-[40vmin] h-[56vmin] object-cover" 
    draggable="false"  
    onMouseDown={() => setDragging(false)} // Réinitialiser l'état de glissement lorsqu'on appuie sur la souris
    onClick={() => openImage(src)}
    width="1200"
    height="800" />
  ))}
</div>
{selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <img src={selectedImage} className="max-w-full max-h-full" 
          width="1200"
          height="800"/>
          <button className="absolute button-gradient top-4 right-4 text-white" onClick={closeImage}>
            Fermer
          </button>
        </div>
      )}
</div>
  );
};

export default Carousel;
