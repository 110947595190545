import React from 'react';
import Hero from '../components/Hero';
import TermsConditions from '../assets/images/Terms&Conditions.webp'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <main>
      <Helmet>
    <title>Conditions Générales de Vente - Patek Verandas</title>
    <meta name="description" content="Consultez les conditions générales de Patek Verandas pour tout savoir sur nos services de véranda, auvent et protection solaire à Lille et dans les régions voisines." />
    </Helmet>
        <Hero image={TermsConditions} titre="Termes & Conditions" height="55"/>
    <div className="container mx-auto my-8 p-8 bg-white shadow rounded">

      <section className="mb-6">
        <h2 className="text-2xl text-gray-700 font-semibold mb-4">Introduction</h2>
        <p>En accédant et en utilisant Patek Verandas, vous acceptez de vous conformer aux termes et conditions suivants, qui, avec notre politique de confidentialité, régissent notre relation avec vous en relation avec ce site web. Si vous n'êtes pas d'accord avec une partie de ces termes et conditions, veuillez ne pas utiliser notre site web.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl text-gray-700 font-semibold mb-4">Droit d'auteur</h2>
        <p>Le contenu de ces pages est pour votre information générale et utilisation uniquement. Il est sujet à changement sans préavis. Ce site web utilise des cookies pour surveiller les préférences de navigation. Si vous autorisez l'utilisation de cookies, les informations personnelles suivantes peuvent être stockées par nous pour être utilisées par des tiers.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl text-gray-700 font-semibold mb-4">Utilisation du site web</h2>
        <p>Ni nous, ni aucun tiers ne fournissons de garantie ou de garantie quant à l'exactitude, l'actualité, la performance, l'exhaustivité ou la pertinence des informations et des matériaux trouvés ou proposés sur ce site web pour un but particulier. Vous reconnaissez que de telles informations et matériaux peuvent contenir des inexactitudes ou des erreurs et nous excluons expressément la responsabilité pour de telles inexactitudes ou erreurs dans toute la mesure permise par la loi.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl text-gray-700 font-semibold mb-4">Limitation de responsabilité</h2>
        <p>Votre utilisation de toute information ou matériel sur ce site web est entièrement à vos risques et périls, pour lesquels nous ne serons pas responsables. Il sera de votre propre responsabilité de vous assurer que tout produit, service ou information disponible sur ce site web répond à vos exigences spécifiques.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl text-gray-700 font-semibold mb-4">Modifications des termes</h2>
        <p>Patek Verandas peut réviser ces termes et conditions à tout moment sans préavis. En utilisant ce site web, vous acceptez d'être lié par la version actuelle de ces termes et conditions.</p>
      </section>

      <div className="text-center mt-8">
        <p>Si vous avez des questions ou des préoccupations concernant ces termes, veuillez nous contacter</p>
        <div className="text-center mt-8">
            <Link to="/contact" className="text-blue-600 hover:underline">Contactez-nous</Link>
        </div>
      </div>
    </div>
    </main>
  );
};

export default TermsAndConditions;
