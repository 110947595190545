// Layout.js
import Header from './Header';
import Footer from './Footer';
import Hero from './Hero';
import Chatbot from './Chatbot';
import WhatsAppButton from './WhatsAppButton';

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <Chatbot />
      <WhatsAppButton/>
    </>
  );
}
