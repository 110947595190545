import React from 'react';
import ArticleCard from './../../components/ArticleCard';
import avantage from './../../assets/images/Articles/Avantage.webp';
import populaire from './../../assets/images/Articles/Populaires.webp';
import entretenir from './../../assets/images/Articles/Entretenir.webp';
import ecologique from './../../assets/images/Articles/Ecologique.webp';
import choisir from './../../assets/images/Articles/Choisir.webp';
import renovation from './../../assets/images/Articles/Renovation.webp';
import principale from './../../assets/images/Articles/Principale.webp';
import Hero from '../../components/Hero';
import { Helmet } from 'react-helmet';

const Articles = () => {
  const articleLinks = [
    { id: 'articleAdvantages', title: 'Les Avantages des Vérandas pour les Maisons', image: avantage },
    { id: 'articleTypes2024', title: 'Types de Vérandas Populaires en 2024', image: populaire },
    { id: 'articleMaintenance', title: 'Comment Entretenir sa Véranda', image: entretenir },
    { id: 'articleEcoFriendly', title: 'Vérandas Écologiques et Durables', image: ecologique },
    { id: 'articleChoose', title: 'Comment Choisir sa Véranda', image: choisir },
    { id: 'articleRenovation', title: 'Projets de Rénovation incluant une Véranda', image: renovation },
  ];

  return (
    <>
    <Helmet>
        <title>Articles - Patek Verandas</title>
        <meta name="description" content="Découvrez nos articles sur les vérandas, incluant des conseils d'entretien, des tendances populaires, et des options écologiques." />
      </Helmet>
    <Hero image={principale} height={55} titre={"Articles"}/>
    <div className="bg-gray-100 py-8 px-4 sm:px-8 lg:px-16">
      <div className="max-w-7xl mx-auto">
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {articleLinks.map(link => (
            <ArticleCard key={link.id} id={link.id} title={link.title} image={link.image} />
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Articles;
