import React from "react";
import notFound from "./../assets/images/404.webp";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center pt-10">
      <h3 className="font-jakartaSans font-bold">Désolé, page non trouvée !</h3>
      <img src={notFound} alt="404 Not found" className="w-[620px]" />
      <Link to="/" >Retourner à la page d'accueil</Link>
    </div>
  );
}

export default NotFoundPage;
